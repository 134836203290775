import { Outlet, useParams, useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import NotFound from "../../../components/NotFound";
import useGetProjectData from "../../../hooks/useGetProjectData";
import useGetUserData from "../../../hooks/useGetUserData";
import Intercom from '@intercom/messenger-js-sdk';
import { useEffect, useState } from "react";
import ClientProvider from "../../../modules/ClientProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useDealContext } from "../../deal";
import { loadProject } from "../../admin/services/ai-chain-service";

export const Project = () => {
    const { projectTag } = useParams();

    const [fetched, user] = useGetUserData();
    const navigate = useNavigate();
    const guestClient = ClientProvider.provideGuestClient();
    const { getAccessTokenSilently } = useAuth0();
    const { dealContext, setDealContext } = useDealContext();
    const [ aiResults, setAiResults ] = useState<any|null>(null);

    const redirectGuest = async () => {
        const { data, error } = await guestClient.login(user.emailAddress, user.name, user.company?.name, dealContext?.project?.project_share_links[0].share_url);
        if (data) {
            localStorage.setItem("jwtToken", data.token);
            navigate(`/guest/${dealContext?.project?.project_share_links[0].share_url}`);
        }
    }

    useEffect(() => {
        if (fetched && user && process.env.REACT_APP_INTERCOM_APP_ID) {
            Intercom({
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                user_id: user.uuid,
                email: user.emailAddress,
                name: user.name,
                created_at: user.createdAt
            });

        } else {
            console.warn('Intercom is not configured');
        }
    }, [fetched, user]);

    useEffect(() => {

        const fetchDealData = async () => {
            const accessToken = await getAccessTokenSilently();
            if (projectTag) {
                const { data, error } = await ClientProvider.provideProjectClient().getProjectData(projectTag, accessToken);
                if (data) {
                    data.project.result_json = data.project.result_json ? JSON.parse(data.project.result_json) : {};
                    data.project.revision_result_json = data.project.revision_result_json ? JSON.parse(data.project.revision_result_json) : {};
                    const aiResults = data.project.user_type === "marketer" ? await loadProject(data.project, accessToken) : null;            

                    if (aiResults?.data) {
                        setAiResults(aiResults?.data?.project);
                    }

                    setDealContext({
                        project: data.project,
                        organization: data.project.organization,
                        role: data.role,
                        fetched: true
                    });
                } else {
                    navigate('/');

                }
            }
        }

        setDealContext({
            project: null,
            organization: null,
            role: null,
            fetched: false
        });
        fetchDealData();

        return () => {
            setDealContext({
                project: null,
                organization: null,
                role: null,
                fetched: false
            });
        }
    }, []);


    if (!dealContext.fetched || !fetched) return <Loading />;

    if (dealContext.fetched && !dealContext.project) return <NotFound />;

    const viewerRole = dealContext.role;

    if (dealContext.project?.experience === "winning") {
        if (dealContext.role === "guest") {
            redirectGuest();
            return <></>;
        } else {
            navigate(`/o/${dealContext.project?.organization?.tag}/d/${dealContext.project?.tag}`);
        }
    }

    return (
        <Outlet context={{ organization: dealContext.project?.organization, project: dealContext.project, viewerRole: viewerRole, aiResults: aiResults }} />
    );
};

