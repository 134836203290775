import ProjectHttpClient from "../core/infrastructure/clients/ProjectHttpClient";
import GuestHttpClient from "../core/infrastructure/clients/GuestHttpClient";

const ClientProvider = {
    provideProjectClient: () => {
        return new ProjectHttpClient();
    },

    provideGuestClient: () => {
        return new GuestHttpClient();
    }
}

export default ClientProvider;