import { useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { useEffect, useState } from "react";
import useAnalytics from "../../../hooks/useAnalytics";
import { useUserContext } from "../../users";
import { DealUsers } from "../components/collaboration/DealUsers";
import { DealGuests } from "../components/collaboration/DealGuests";
import { SharingControls } from "../components/collaboration/DealSharingControls";
import { styled, Tab, Tabs } from "@mui/material";

const CollaborationTabs = styled(Tabs)(( { theme }) => ({
    display: "flex",
    marginBottom: "20px",
    width: "100%",
    borderBottom: "1px solid #c9c9c9",
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
        borderBottom: "none",
        color: "#000000"
    }
}));

const CollaborationTab = styled(Tab)(( { theme }) => ({
    borderLeft: "1px solid #ffffff",
    borderTop: "1px solid #ffffff",
    borderRight: "1px solid #ffffff",
    borderBottom: "none",
    fontWeight: "500",
    fontSize: "1rem",
    "&.Mui-selected": {
        color: "#000000",
        backgroundColor: "#f8f9fa",
        borderLeft: "1px solid #c9c9c9",
        borderTop: "1px solid #c9c9c9",
        borderRight: "1px solid #c9c9c9",
        borderBottom: "none",

        "&:hover": {
            backgroundColor: "#f8f9fa",
            color: "#000000"
        }
    },
    "&:hover": {
        backgroundColor: "#ffffff",
        color: "#000000"
    }
}));


export const DealCollaboration = () => {
    const { project, organization, viewerRole } = useOutletContext<{ project: any, organization: any, viewerRole: any }>();
    const { userContext } = useUserContext();
    const [ value, setValue ] = useState(0);


    const { trackPage } = useAnalytics();

    useEffect(() => {
        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - collaboration`, { organization: organization.tag, project: project.tag, role: userContext.projectMemberships.role });
        }
    }, [userContext]);

    const handleTabSwitch = (event: any, newValue: any) => {

        setValue(newValue);
    };

    return (
        <div>
            <DealTitle viewerRole={viewerRole} project={project} section="Team and Guests" />

            <CollaborationTabs value={value} onChange={handleTabSwitch} aria-label="collaboration tabs">
                <CollaborationTab label="External Guest Viewers" />
                <CollaborationTab label={`Team Members (${organization.name}, ${project.name})`} />
            </CollaborationTabs>
            { value === 0 && <>
                <DealGuests project={project} viewerRole={viewerRole} /></>
            }
            { value === 1 && <DealUsers project={project} viewerRole={viewerRole} /> }


        </div>
    );
}   