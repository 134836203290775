import NavBar from "../../../components/NavBar";
import { Box, styled, ThemeProvider } from "@mui/material";
import { theme } from "../../../theme/theme";
import { useEffect, useState } from "react";
import { useUserContext } from "../../users";
import ClientProvider from "../../../modules/ClientProvider";
import { useGuestAuthContext } from "..";
import { useDealContext } from "../../deal";

type PageProps = {
    children: any
}

const GuestBannerBox = styled(Box)(({ theme }) => ({
    //backgroundColor: theme.palette.primary.main,
    backgroundColor: "#feb70f",
    //color: theme.palette.primary.contrastText,
    color: "#000000",
    padding: '1rem',
    textAlign: 'left',
    marginBottom: '1rem',
    position: "absolute",
    width: "100%",
    top: "70px",
    zIndex: 1000,
    paddingLeft: "40px",
    paddingRight: "40px",
}));


const demoUrl = "https://link.brightloop.ai/meetings/abrydon/request-demo?uuid=9b387959-18bf-4ca7-9ba9-3c8b959e9cef";

export const GuestBanner = () => {

    const { dealContext } = useDealContext();
    const { token, loading } = useGuestAuthContext();
    const [invitation, setInvitation] = useState<any>(null);
    const [ invitationFetched, setInvitationFetched ] = useState(false);

    const client = ClientProvider.provideGuestClient();

    useEffect(() => {

        const fetchSandboxInvitation = async () => {
            if (!token) {
                return
            }

            const { data, error } = await client.getSandboxInvitation(token)
            if (data) {
                setInvitation(data.invitation);
                setInvitationFetched(true);
            }
        }

        if (!loading) {
            fetchSandboxInvitation();
        }

    }, [loading]);

    if (!dealContext.fetched || loading || !invitationFetched) {
        return (
            <GuestBannerBox>
            </GuestBannerBox>
        )
    }
        

    const visibleExecutives = dealContext.project?.project_share_links && (dealContext.project.project_share_links[0].filter_interest_demo || dealContext.project.project_share_links[0].filter_interest_invest || dealContext.project.project_share_links[0].filter_interest_advise) ? "Engaged" : "All";

    return (

                    <GuestBannerBox>
                        This dealspace is powered by <a href="https://www.brightloop.ai" target="_blank" style={{ color: "#000000", fontWeight: "bold", textDecoration: "underline" }}>BrightLoop</a>. You are viewing viewpoints from {visibleExecutives} Executives as a Guest. <a href={demoUrl} target="_blank" style={{ color: "#000000", fontWeight: "bold", textDecoration: "underline" }}>Click here</a> to schedule a tour of this page.
                    </GuestBannerBox>

    );
};
//about how you can generate insights and opportunities for your own deals, <span className="link" style={{ color: "#000000", fontWeight: "bold", textDecoration: "underline"}}>click here</span> for a demo or <span className="link" style={{ color: "#000000", fontWeight: "bold", textDecoration: "underline"}}>click here</span> for a sandbox.