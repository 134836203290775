import { useGetProjectMembers } from "../../../../hooks/useGetProjectMembers";
import { useGetProjectInvitations } from "../../../../hooks/useGetProjectInvitations";
import { Box, styled, TableContainer, Typography, Table, TableHead, TableRow, TableBody, TableCell, Button } from "@mui/material";
import { DealMembersTable } from "./DealMembersTable";
import { DealInvitationControl } from "../sharing/DealInvitationModal";
import { useState } from "react";
import Loading from "../../../../components/Loading";

const DealUsersBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    height: "100%",
});

export const DealUsers = ({ project, viewerRole }: any) => {

    const [inviteActive, setInviteActive] = useState(false);

    const membersData = useGetProjectMembers(project.id ?? "");
    const invitationsData = useGetProjectInvitations(project.id ?? "");

    const members = membersData?.members?.filter((member: any) => !(member.role === "guest"));
    const invitations = invitationsData?.invitations?.filter((invitation: any) => !invitation.is_redeemed && !(invitation.invitee_role === "guest"));

    const handleOpenInvite = () => {
        setInviteActive(true);
    }


    return (
        <DealUsersBox>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box>
                    <Typography variant="body1">Team Members can interact with Executives who add viewpoints. Admins can manage Team Members and Guest Access.</Typography>
                </Box>
            </Box>

            {(!membersData.fetched || !invitationsData.fetched) &&
                <Box sx={{ paddingTop: "80px"}}>
                    <Loading />
                </Box>
            }

            {membersData.fetched && invitationsData.fetched &&
                <DealMembersTable membersType="members" viewerRole={viewerRole} project={project} members={members} invitees={invitations} reloadMembers={membersData.reloadProjectMembers} reloadInvitations={invitationsData.reloadProjectInvitations} />
            }
            <DealInvitationControl project={project} role="collaborator" active={inviteActive} setActive={setInviteActive} reloadInvitations={invitationsData.reloadProjectInvitations} />
        </DealUsersBox>
    );
}
