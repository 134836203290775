import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import useAdminGetProjectData from "../hooks/useAdminGetProjectData";
import { useDealContext } from "../../deal";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";


export const AdminProject = () => {
    const { projectId } = useParams();
    const { fetched, organization, project, error, reloadProjectData } = useAdminGetProjectData(parseInt(projectId ?? ""));

    const { dealContext, setDealContext } = useDealContext();

    useEffect(() => {

        if (project) {
            setDealContext({
                project: project,
                organization: project.organization,
                role: "admin",
                fetched: true
            });

        }

        return () => {
            setDealContext({
                project: null,
                organization: null,
                role: null,
                fetched: false
            });
        }
    }, [fetched]);

    if (!fetched) {
        return <div>Loading...</div>;
    }

    if (!project) return;

    return (
        <div>
            <div className="admin-project-header">
                <h4>{project.organization.name} &raquo; {project?.name}</h4>
                <div className="admin-subnav-bar">
                    <NavLink to={`/admin/project/${project.id}`} end>Home</NavLink>
                    <NavLink to={`/admin/project/${project.id}/edit`}>Eval Config</NavLink>
                    <NavLink to={`/admin/project/${project.id}/surveyLinks`}>Survey Links</NavLink>
                    <NavLink to={`/admin/project/${project.id}/surveyResponses`}>Responses</NavLink>
                    <NavLink to={`/admin/project/${project.id}/viewpoints`}>Viewpoints</NavLink>
                    <NavLink to={`/admin/project/${project.id}/deal`}>Deal Config</NavLink>
                    <NavLink to={`/admin/project/${project.id}/investmentMaterials`}>Investment Materials</NavLink>
                    <NavLink to={`/admin/project/${project.id}/dealUpdates`}>Deal Updates</NavLink>
                    <NavLink to={`/admin/project/${project.id}/notifications`}>Notifications</NavLink>
                    <NavLink to={`/admin/project/${project.id}/ai`}>AI</NavLink>
                </div>
            </div>
            <Outlet context={{ project: project, organization: organization, reloadProjectData: reloadProjectData }} />
        </div>
    );
}