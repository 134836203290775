import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Dialog, Stack, TextField, Typography } from "@mui/material";
import ClientProvider from "../../../../modules/ClientProvider";


export const GenerateSetupLink = ({ project, open, setOpen }: any) => {
    const { getAccessTokenSilently } = useAuth0();
    const projectClient = ClientProvider.provideProjectClient();
    const [invitationLink, setInvitationLink] = useState("");

    const handleGenerate = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.generateContributorLink(project.id, accessToken);
        if (data) {
            setInvitationLink(data.invitation_link);
            navigator.clipboard.writeText(data.invitation_link);
        } else {
            console.error(error);
        }
    };


    const handleCancel = () => {
        setInvitationLink("");
        setOpen(false);
    }


    return (
        <Dialog open={open} onClose={handleCancel}>
            <Typography variant="h5">Generate Setup Link</Typography>
            <p>Create a link that can be forwarded to the Founder. The link is good for 7 days and can be used by anyone to sign up as a user in the project.</p>
            <Box>
                <Stack spacing={2} sx={{ width: "100%" }}>
                    <TextField
                        id="invitationLink"
                        label="Invitation Link"
                        size="small"
                        value={invitationLink}
                        disabled
                    />
                </Stack>
            </Box>


            {invitationLink === "" &&
                <>
                    <Button variant="contained" disabled={invitationLink !== ""} onClick={handleGenerate}>Generate Link</Button>
                    <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                </>
            }

            {invitationLink !== "" &&
                <>
                    <Typography color="success" sx={{ textAlign: "center" }}>Copied to clipboard</Typography>
                    <Button variant="outlined" onClick={handleCancel}>Close</Button>
                </>
            }
        </Dialog>
    );
}