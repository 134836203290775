import { Box, CircularProgress } from "@mui/material";

const Loading = () => (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <CircularProgress />
    </Box>

);

export default Loading;
