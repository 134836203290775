import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProjectInvitations } from "../services/gutcheck-admin-api.service";

type ProjectInvitationData = {
    fetched: boolean;
    invitations: any;
    error: { message: any } | null;
}

const initState: ProjectInvitationData = {
    fetched: false,
    invitations: [],
    error: null
}

export const useGetProjectInvitations = (projectId: number) => {


    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(initState);

    const reloadProjectInvitations = async () => {
        setState({
            ...state,
            fetched: false
        });

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getProjectInvitations(projectId, accessToken);

        if (data) {
            const fetchedProjectInvitations: ProjectInvitationData = {
                fetched: true,
                invitations: data.invitations ?? [],
                error: error
            };

            setState(fetchedProjectInvitations);

        } else {
            console.error(error);
        }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchProjectInvitations = async () => {
            if (!isMounted) return;
            reloadProjectInvitations();
        };

        fetchProjectInvitations();

        return () => { isMounted = false };
    }, []);

    return { ...state, reloadProjectInvitations };
};
