import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import NotFound from "../../../components/NotFound";
import { EmailInputScreen } from "./EmailInputScreen";
import ClientProvider from "../../../modules/ClientProvider";
import { useGuestAuthContext } from "./GuestContext";

type TAuthentication = {
    Component: React.ElementType,
    role?: string
}

export const GuestAuthenticationGuard = ({ Component, role="guest" }: TAuthentication) => {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const [ authLoading, setAuthLoading ] = useState(true);
    const { token, loading, setToken } = useGuestAuthContext();

    const { shareLinkId } = useParams();


    useEffect(() => {
        const checkGuestAuth = async () => {
            if (!token) {
                setAuthLoading(false);
                return;
            }

            try {
                const client = ClientProvider.provideGuestClient();
                const { data, error } = await client.verifyToken(token, shareLinkId || "");

                if (error) {
                    console.error(error);
                    setAuthLoading(false);
                }

                if (data) {
                    setToken(data.token);
                    localStorage.setItem('jwtToken', data.token);
                    setAuthenticated(true);
                }

                setAuthLoading(false);
            } catch (error) {
                console.error(error);
                setAuthLoading(false);
            }
        };

        if (!loading) {
            checkGuestAuth();
        }
    }, [loading]);



    if (authLoading) {
        return <Loading />;
    }


    if (!authenticated) {
        return <EmailInputScreen redirectUrl={window.location.pathname} setAuthenticated={setAuthenticated}/>;
    }

    return <Component />;
};