import { styled, Tooltip } from "@mui/material";

const ProfileImageBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#f1f1f1',
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    overflow: 'hidden',
    cursor: 'default',
    "&.small": {
        width: '30px',
        height: '30px',
        fontSize: '1rem',
    },
    "&.large": {
        width: '100px',
        height: '100px'
    },
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    }
});

const defaultIconSvg = '<svg width="187" height="187" viewBox="0 0 187 187" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_847_348" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="187" height="187"><circle cx="93.5" cy="93.5" r="93.5" fill="#F8F9FA"/></mask><g mask="url(#mask0_847_348)"><circle cx="93" cy="94" r="93" fill="#F8F9FA"/><circle cx="93.5" cy="191.5" r="85.5" fill="#667788"/><circle cx="94" cy="80" r="41" fill="#C9C9C9"/></g></svg>';


export const UserProfileImage = ({ user, size="medium", pending=false }: any) => {

    if (!user || !(user.name || user.email_address || user.emailAddress)) {
        return (
            <Tooltip title={`${pending ? " (pending)": ""}`} placement="top">
            <ProfileImageBox className={size}>
                <span></span>
            </ProfileImageBox>
            </Tooltip>
        );
    }

    const imageUrl = user.image_url ?? user.imageUrl ?? null;
    const emailAddress = user.email_address ?? user.emailAddress ?? null;

    const handleImageError = (e: any) => {
        const srcString = `data:image/svg+xml,${encodeURIComponent(defaultIconSvg)}`;
        e.target.src = srcString
    }


    if (imageUrl === null || /cdn\.auth0\.com/.test(imageUrl)) {

        const initials = user.name ? user.name.split(" ").map((n: string) => n[0]).join("") : emailAddress[0] + emailAddress[1];

        const placeholderImage = `https://cdn.auth0.com/avatars/${initials.toLowerCase()}.png`;
        return (
            <Tooltip title={`${user.name ?? emailAddress}${pending ? " (pending)": ""}`} placement="top">
            <ProfileImageBox className={size}>
                <img src={placeholderImage} alt={user.name} onError={handleImageError}/>
            </ProfileImageBox>
            </Tooltip>
        );
    }

    return (
        <Tooltip title={user.name ?? emailAddress} placement="top">
        <ProfileImageBox className={size}>
            <img src={imageUrl} alt={user.name} onError={handleImageError}/>
        </ProfileImageBox>
        </Tooltip>
    );
}