import { Box, styled } from "@mui/material";
import { useSearchParams } from "react-router-dom"

const PopoutBox = styled(Box)(({ theme }) => ({
    padding: "20px",
    borderRadius: "4px",
    width: "100%",
    maxWidth: "800px",
    margin: "0 auto",
    marginTop: "20px",
    "& h1": {
        fontSize: "2rem",
        marginBottom: "20px"
    }
}));


export const EvaluationPopout = () => {

    // get the platform from the URL
    const [ searchParams ] = useSearchParams();
    const platform = searchParams.get('platform');

    return (
        <PopoutBox >
            <h1>Popout</h1>
            <p>Instructions for: {platform}</p>
        </PopoutBox>
    )
}