import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Typography, Box, TextField, Stack, Button, styled } from "@mui/material"
import { useState } from "react";
import { Link } from "react-router-dom";

const SuccessButton = styled(Button)(( { theme }) => ({
    borderColor: "#4caf50 !important",
    color: "#4caf50 !important",
    backgroundColor: "#ffffff !important",
    "&:hover": {
        backgroundColor: "#388e3c",
    }
}));

export const DealGuestShare = ({ project, active, setActive }: any) => {

    const [ copied, setCopied ] = useState(false);

    const shareLink = project.project_share_links[0];

    const shareUrl = `${window.location.origin}/guest/${shareLink?.share_url}`;

    const visibleExecutives = shareLink.filter_interest_demo || shareLink.filter_interest_invest || shareLink.filter_interest_advise ? "Engaged" : "All";
    const sections = [];
    if (shareLink.display_prospects) sections.push("Prospects");
    if (shareLink.display_investors) sections.push("Angels");
    if (shareLink.display_viewpoints) sections.push("Viewpoints");
    if (shareLink.display_analysis) sections.push("Analysis");

    const handleCopy = () => {
        navigator.clipboard.writeText(shareUrl);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    }

    return (
        <Dialog open={active} onClose={() => setActive(false)}>
            <Box>
                <h5>Share with Guests</h5>
                <p>
                Guests can see {
                    sections.map((section, index) => {
                        return (
                            <span key={`${section}-${index}`}>
                                <strong key={index}>{section}</strong>
                                {index === sections.length - 1 ? "" : (index === sections.length - 2 ? " and " : ", ")}
                            </span>
                        )
                    })
                } from <strong>{visibleExecutives}</strong> Executives. See who has access <Link to={`/o/${project.organization.tag}/d/${project.tag}/collaboration`}>here</Link>.</p>
                <Stack spacing={2} sx={{ marginTop: "20px"}}>
                    { !copied && <Button onClick={handleCopy} variant="contained" color="primary"><FontAwesomeIcon icon="link"/>&nbsp;&nbsp;Copy Link</Button> }
                    { copied && <SuccessButton disabled variant="contained" ><FontAwesomeIcon icon="link"/>&nbsp;&nbsp;Link Copied! </SuccessButton> }
                </Stack>
            </Box>

        </Dialog>
    )



}
