import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem, styled } from '@mui/material';
import { useDealContext } from '../../context';
import { DealInvitationControl } from './DealInvitationModal';
import { useGetProjectInvitations } from '../../../../hooks/useGetProjectInvitations';
import { DealGuestShare } from './DealGuestShare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShareMenu = styled(Menu)(({ theme }) => ({
    "& .MuiMenu-paper": {
        borderRadius: "4px",
    },
    "& li": {
        borderBottom: "1px solid #c9c9c9",
        "&:last-child": {
            borderBottom: "none",
        }
    }
}));

export const DealShareButton = ({ project }: any) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { dealContext } = useDealContext();
    const [inviteActive, setInviteActive] = useState(false);
    const [shareActive, setShareActive] = useState(false);
    const invitationsData = useGetProjectInvitations(project.id ?? "");

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInviteClick = () => {
        setInviteActive(true);
        setAnchorEl(null);
    }

    const handleShareClick = () => {
        setShareActive(true);
        setAnchorEl(null);
    }

    return (
        <Box>
            <Button variant="contained" color="primary" onClick={handleClick}><FontAwesomeIcon icon="user-plus" />&nbsp;&nbsp;Share</Button>
            <ShareMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}

            >
                <MenuItem onClick={handleInviteClick}>Invite Team Member ({project.name}, {project.organization.name})</MenuItem>
                <MenuItem onClick={handleShareClick}>Share with External Guests</MenuItem>
            </ShareMenu>
            <DealInvitationControl project={project} role={dealContext?.role} active={inviteActive} setActive={setInviteActive} reloadInvitations={invitationsData.reloadProjectInvitations} />
            <DealGuestShare project={project} active={shareActive} setActive={setShareActive} />
        </Box>
    );
}