import StyledDropzone from "../../../../components/AssetUpload";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Stack, styled, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import type { DialogProps } from "@mui/material";
import { uploadAsset, uploadLogo } from "../../../../core/services/gutcheck-api.service";
import ProjectAsset from "../../../projects/types/ProjectAsset";
import ClientProvider from "../../../../modules/ClientProvider";
import { v4 } from "uuid";
import CollaborativeEditor from "../../../../components/CollaborativeEditor";
import { ModalBox } from "../../../../components/ModalBox";

const FormatButton = styled('button')({
    backgroundColor: "#f8f9fa",
    border: "none",
    color: "black",
    padding: "10px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "1.2rem",
});

const EditorBox = styled(Box)({
    aspectRation: '16/9',
    width: '100%',
    "& .ProseMirror": {
        height: "calc(100vh - 340px)",
        maxHeight: "600px",
        overflow: "auto",
        width: '100%',
        border: '1px solid #c9c9c9',
        backgroundColor: "#ffffff",
        padding: '1rem',
        borderRadius: "4px"
    }

});

const UploadBox = styled(Box)(({ theme }) => ({
    width: "100%",
    aspectRatio: '16/9',
    overflowY: "auto",
    "& img": {
        maxWidth: "100%",
        height: "auto",
    }
}));


export const DealAssetForm = ({ project, dealAsset, dealAssets, reloadDealAssets, editMode, setEditMode, dealAssetType }: any) => {
    const { getAccessTokenSilently } = useAuth0();


    const initialProjectAsset: any = {
        id: dealAsset?.id ?? null,
        uuid: dealAsset?.uuid ?? null,
        type: dealAsset?.type && dealAsset?.type !== "" ? dealAsset.type : dealAssetType,
        format: dealAsset?.format ?? (dealAssetType === "Product Description" ? "text" : "file"),
        title: dealAsset?.title ?? "",
        filename: dealAsset?.filename ?? "",
        description: dealAsset?.description ?? "",
        asset_url: dealAsset?.asset_url ?? "",
        asset_data: dealAsset?.asset_data ?? "",
        asset_file_id: dealAsset?.asset_file_id ?? "",
        thumbnail_url: dealAsset?.thumbnail_url ?? "",
        is_deleted: dealAsset?.is_deleted ?? false,
    }

    const [projectAssetForm, setProjectAssetForm] = useState(initialProjectAsset);

    const initQueuedFiles: File[] = [];
    const [queuedFiles, setQueuedFiles] = useState(initQueuedFiles);
    const [assetText, setAssetText] = useState(projectAssetForm?.asset_data);
    const [previewLink, setPreviewLink] = useState(dealAsset?.asset_url);
    const [editTitle, setEditTitle] = useState(dealAsset?.title && dealAsset?.title !== "" ? false : true);
    const [submitting, setSubmitting] = useState(false);

    const filename = dealAsset?.filename ?? `${project.organization.tag}/${project.tag}/asset-${dealAssets.filter((asset: ProjectAsset) => asset.type === dealAssetType).length + 1}`;
    const isInitialProductDescription = dealAssets.length === 0 && dealAssetType === "Product Description";
    if (isInitialProductDescription) {
        projectAssetForm.title = "Main Product Description";
    }

    useEffect(() => {
        if (queuedFiles.length > 0 && projectAssetForm.format === "file" && projectAssetForm.title === "") {
            setProjectAssetForm({
                ...projectAssetForm,
                title: queuedFiles[0].name
            });
        }
    }, [queuedFiles]);


    const handleSubmit = async () => {
        setSubmitting(true);
        const accessToken = await getAccessTokenSilently();

        let asset_file_id = projectAssetForm.asset_file_id;
        if (queuedFiles.length > 0) {
            const { data, error } = await uploadAsset(queuedFiles[0], project.organization.tag, project.tag, queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            asset_file_id = data.response.location;
        }

        const newProjectAsset = projectAssetForm;
        newProjectAsset.asset_file_id = asset_file_id;

        if (projectAssetForm.format === "text") {
            newProjectAsset.asset_data = assetText;
            newProjectAsset.filename = filename;
        }


        if (dealAsset?.id) {
            await ClientProvider.provideProjectClient().updateAsset(project.id, dealAsset.id, newProjectAsset, accessToken);
        } else {
            await ClientProvider.provideProjectClient().createAsset(project.id, newProjectAsset, accessToken);

        }
        setProjectAssetForm(initialProjectAsset);
        await reloadDealAssets();
        setEditMode(false);
    }

    const handleChange = (event: any) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setProjectAssetForm({ ...projectAssetForm, [event.target.name]: value });
    }

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setEditMode(false);
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        setEditMode(false);
    }

    const handleFormatChange = (event: any) => {
        setProjectAssetForm({
            ...projectAssetForm,
            format: event.target.value
        });
    }

    const handleLinkPreview = () => {
        setPreviewLink(projectAssetForm.asset_url);
    }

    const handleDelete = async () => {
        const accessToken = await getAccessTokenSilently();
        await ClientProvider.provideProjectClient().deleteAsset(project.id, projectAssetForm.id, accessToken);
        await reloadDealAssets();
        setEditMode(false);
    }

    const handleEditName = () => {
        setEditTitle(true);
    }

    return (
        <Modal
            open={editMode} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox sx={{ width: "900px" }}>

                    <div className="admin-modal-intro">
                        <h5>{dealAsset?.id ? "Edit Your" : "Add a"} {dealAssetType}</h5>
                    </div>


                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        gap: "20px",
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "60%",
                        }}>


                            { !isInitialProductDescription &&
                                <>
                                    {editTitle ? <TextField name="title" value={projectAssetForm.title} onChange={handleChange} label="Name" size="small" /> : <h5>Name: {projectAssetForm.title} (<span className="link" onClick={handleEditName}>change</span>)</h5>}
                                </>
                            }

                            {!projectAssetForm.id &&
                                <ToggleButtonGroup exclusive aria-label="format" value={projectAssetForm.format} onChange={handleFormatChange}>
                                    {dealAssetType === "Product Description" && <ToggleButton value="text">Enter your content</ToggleButton>}
                                    <ToggleButton value="file">Upload a file</ToggleButton>
                                    {dealAssetType !== "Product Description" && <ToggleButton value="link">Provide a link</ToggleButton>}
                                </ToggleButtonGroup>
                            }

                            {projectAssetForm.format === "text" &&
                                <EditorBox>
                                    <CollaborativeEditor documentName={filename} form={assetText} setForm={setAssetText} />
                                </EditorBox>
                            }

                            {projectAssetForm.format === "file" &&
                                <UploadBox>
                                    <StyledDropzone fileTypes={{ 'image/*': [], 'video/*': [], 'application/pdf': [] }} queuedFiles={queuedFiles} setQueuedFiles={setQueuedFiles} initialFile={projectAssetForm.asset_file_id ?? ""} />
                                </UploadBox>
                            }

                            {projectAssetForm.format === "link" &&
                                <>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}>
                                        <TextField name="asset_url" value={projectAssetForm.asset_url} onChange={handleChange} label={`URL of ${projectAssetForm.type}`} size="small" /> <span className="link" onClick={handleLinkPreview}>Preview</span>
                                    </Box>
                                    {previewLink && previewLink !== "" &&
                                        <iframe src={previewLink} width="100%" height="400px"></iframe>
                                    }
                                </>
                            }

                            {submitting && <p>Submitting...</p>}
                            {!submitting &&
                                <Stack direction="row" spacing={2} alignContent="center" justifyContent="space-between" alignItems="center">
                                    <Box>
                                    { dealAsset?.id && <Typography className="link" sx={{ color: "red" }} onClick={handleDelete}>Delete</Typography> }
                                    </Box>
                                    <Stack direction="row" spacing={2} alignContent="center" justifyContent="flex-end">
                                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                                        <button type="button" onClick={handleSubmit}>Submit</button>
                                    </Stack>
                                </Stack>
                            }
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            flex: 1,
                            fontSize: "0.9rem",
                        }}>
                            {dealAssetType === "Product Description" &&
                                <div className="tips-content">
                                    <p><span className="bold">Format & Instructions</span></p>
                                    <ul>
                                        <li>Simple product description</li>
                                        <li>Start with user value prop</li>
                                        <li>Focus on user behavior</li>
                                        <li>Minimize aspirational fluff</li>
                                        <li>Include objection handling if you already know some objections</li>
                                    </ul>
                                    <p><span className="bold">Example</span><br />
                                        Suppose there was an app on your phone where you could quickly order a ride, taxi or limo to take you from exactly where you were to your destination.</p>
                                    <p>When you launched the app, it would use the GPS on your phone to locate where you were and show you your location on a map. The map would also show you the drivers that were nearby and available to pick you up. You would be able to enter your destination on the phone, and the app would calculate the fare. Once you approved it, the closest driver to accept the fare would pick you up and drop you off. </p>
                                    <p>All drivers would be approved by the service, and you could elect to choose only licensed drivers like limos and taxis, or an everyday driver. You'd have the ability to rate each driver so only the best drivers were able to drive passengers. </p>
                                    <p>Once you reached your destination, you'd simply get out of the car. Your credit card would be billed via the app and distributed to your driver offline. </p>
                                </div>
                            }
                            {projectAssetForm.type === "Sales Deck" &&
                                <div className="tips-content">
                                    <p><span className="bold">Notes</span></p>
                                    <p>This will be blinded and may be presented as supplementary material behind the product description.</p>
                                </div>
                            }

                            {projectAssetForm.type === "Product Demo" &&
                                <div className="tips-content">
                                    <p><span className="bold">Notes</span></p>
                                    <p>This may be presented as supplementary material behind the product description.</p>
                                </div>
                            }

                        </Box>
                    </Box>

            </ModalBox>
        </Modal>
    );
}