import { useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { DealAngelsList } from "../components/DealAngelsList";
import { useEffect } from "react";
import useAnalytics from "../../../hooks/useAnalytics";
import { useUserContext } from "../../users";

export const DealIndustryAngels = () => {
    const { project, organization, viewerRole, user } = useOutletContext<{ project: any, organization: any, viewerRole: any, user: any }>();
    const { userContext } = useUserContext();
    const { trackPage } = useAnalytics();

    useEffect(() => {
        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - angels`, { organization: organization.tag, project: project.tag, role: userContext.projectMemberships.role });
        }
    }, [userContext]);

    return (
        <div>
            <DealTitle viewerRole={viewerRole} project={project} section="Industry Angels" />
            <DealAngelsList project={project} viewpoints={project.viewpoints} user={user} angelStatus="Funded" />
            <DealAngelsList project={project} viewpoints={project.viewpoints} user={user} angelStatus="Reserved" />
            <DealAngelsList project={project} viewpoints={project.viewpoints} user={user} angelStatus="Soft-Circled" />
        </div>
    );
}   