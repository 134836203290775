import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getProjects } from "../services/gutcheck-admin-api.service";

type TProjects = {
    projects: any | null,
    fetched: boolean
}

const initState: TProjects = {
    projects: null,
    fetched: false
}

const useGetProjects = () => {
    const [ state, setState ] = useState(initState);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const { data, error } = await getProjects(accessToken);

            if (data) {
                setState({
                    ...state,
                    projects: data.projects,
                    fetched: true
                });
            } else {
                setState({
                    ...state,
                    fetched: true
                })
            }
        };

        fetchData();

        return () => {};
    }, []);

    return [state.projects, state.fetched];
};

export default useGetProjects;