
import { Document, Page } from "react-pdf";
import { v4 } from "uuid";
import Loading from "../../../components/Loading";
import { Fragment } from "react";
import { Viewpoints } from "../../viewpoints";
import { TDeleteProject, TFormatText, TLabel, TLoading, TNewProject, TProject, TOverviewContainer, TResultBox, TResultBoxes, TResultContent, TAssetContent, TResultSummaryContent, TResultsContainer, TResultsTabs, TRevisionContainer, TRevisionContent, TRevisionDetailsVerbose, TRevisionResults, TRevisionTab, TProjectOverview, TSidebarContainer, TAiContainer, TAiContent, TAiRevision, TAiRevisionContainer, TAiRevisionDetailsVerbose, TAiAssetContent } from "../types/types";
import TextViewer from "../../../components/TextViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { ProjectGuestViewers } from "./project_guest_component";
import useGetLeadsData from "../../../hooks/useGetLeadsData";
import { AdminReloadStats } from "../../../features/admin";


const projectStatus = [
  { value: "draft", label: "Setup & Launch" },
  { value: "evaluation", label: "Collecting Viewpoints" },
  { value: "analysis", label: "Analysis & Results" },
  { value: "firm_investment", label: "Firm Investment" },
  { value: "spv", label: "BrightLoop Expert SPV" },
  { value: "completed", label: "Completed" }
];


export const Loader = ({ isLoading }: TLoading) => {
  return (
    isLoading ? (
      <div className="loader">
        <Loading />
      </div>
    ) : ""
  );
};

const FormattedRevisionText = ({ text, highlights = [], highlightedIndex = '' }: TFormatText) => {
  // Messy test to see if the text is HTML or plain text
  const separators = highlights;
  separators.push("\\n");
  const regex = "(" + separators.join("|") + ")";
  const parts = text?.split(new RegExp(`${regex}`, 'g'));

  if (/<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(text ?? "")) {
    const textParts = parts?.map(part => highlights.includes(part) ? `<span key=${v4()} class="${"highlight " + highlightedIndex}">${part}</span>` : part === "\n" ? `<br />` : `${part}`)
    const newText = textParts?.join("");
    return (
      <div dangerouslySetInnerHTML={{__html: newText ?? ""}} />
    );
  } else {

    return <>{parts?.map(part => highlights.includes(part) ? <span key={v4()} className={"highlight " + highlightedIndex}>{part}</span> : part === "\n" ? <br /> : <span key={v4()}>{part}</span>)}</>;
  }
};

const FormattedText = ({ text, highlights = [], highlightedIndex = '' }: TFormatText) => {
  // Messy test to see if the text is HTML or plain text
  if (/<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(text ?? "")) {
    return (
      <TextViewer content={text ?? ""} />
    );
  } else {

    const separators = highlights;
    separators.push("\\n");
    const regex = "(" + separators.join("|") + ")";
    const parts = text?.split(new RegExp(`${regex}`, 'g'));
    return <>{parts?.map(part => highlights.includes(part) ? <span key={v4()} className={"highlight " + highlightedIndex}>{part}</span> : part === "\n" ? <br /> : <span key={v4()}>{part}</span>)}</>;
  }
};

const DetailsContainer = ({ project }: TProject) => {
  return (
    <div className="project-field-side-container">
      <div className="details-container">
        <div className="">
          <h5>Project Type</h5>
          <div className="ov-detail-box-info">
            <p>{project.iterations?.at(-1)?.type.title}</p>
          </div>
        </div>
        <div className="ov-detail-box">
          <h5>Goals</h5>
          <div className="ov-detail-box-info">
            <p>
              {project.iterations?.at(-1)?.goals.map((g: any) => {
                return (<Fragment key={v4()}>{g.title}<br /></Fragment>)
              })}
            </p>
          </div>
        </div>
        <div className="ov-detail-box">
          <h5>Target Audience</h5>
          <div className="ov-detail-box-info">
            <FormattedText text={project.iterations?.at(-1)?.target_audience} />
          </div>
        </div>
        {project.iterations?.at(-1)?.key_questions && project.iterations?.at(-1)?.key_questions !== "" &&
          <div className="ov-detail-box">
            <h5>Key Questions</h5>
            <div className="ov-detail-box-info">
              <FormattedText text={project.iterations?.at(-1)?.key_questions} />
            </div>
          </div>}
      </div>
    </div>
  );
};

export const ProjectsContainer = ({ project, assetPagination, handleAssetLoad, handleAssetChange }: TAssetContent) => {
  const fileId = project.iterations?.at(-1)?.asset_file_id ?? "";
  const assetUrl = project.iterations?.at(-1)?.asset_url ?? "";
  const isPdf = (/\.pdf/.test(fileId));
  return (

    <div className="project-container">
      <h3 className="bold">Original Asset</h3>
      <div className="project-asset-container">
        {(project.iterations?.at(-1)?.asset_text !== "" && fileId === "" && assetUrl === "") &&
          <FormattedText text={project.iterations?.at(-1)?.asset_text} />
        }
        {(fileId !== "") && (isPdf) &&
          <>
            <Document file={project.iterations?.at(-1)?.asset_file_id} onLoadSuccess={handleAssetLoad}>
              <Page pageNumber={assetPagination.currentPage} renderTextLayer={false} renderAnnotationLayer={false} scale={1.6} />
            </Document>
            <div className="analysis-pagination">
              <button
                className="btn btn-disabled"
                type="button"
                disabled={assetPagination.currentPage <= 1}
                onClick={e => handleAssetChange(e, -1)}
              >
                Previous
              </button>
              <span>
                {assetPagination.currentPage || (assetPagination.numPages ? 1 : '--')} of {assetPagination.numPages || '--'}
              </span>

              <button
                className="btn"
                type="button"
                disabled={assetPagination.currentPage >= assetPagination.numPages}
                onClick={e => handleAssetChange(e, 1)}
              >
                Next
              </button>
            </div>
          </>
        }
        {(fileId !== "") && !(isPdf) &&
          <video className="asset-url-container" src={fileId} controls></video>
        }
        {(assetUrl !== "") &&
          <iframe className="asset-url-container"
            src={project.iterations?.at(-1)?.asset_url}>
          </iframe>
        }
      </div>
    </div>
  )
};

export const AiProjectsContainer = ({ project, assetPagination, handleAssetLoad, handleAssetChange, assetType }: TAiAssetContent) => {
  const fileId = (assetType === "revision") ? project.iterations?.at(-1)?.revision_url ?? "" : project.iterations?.at(-1)?.asset_file_id ?? "";
  const assetUrl = project.iterations?.at(-1)?.asset_url ?? "";
  const isPdf = (/\.pdf/.test(fileId));
  return (

    <div className="project-container">
      <h3 className="bold">{assetType === "revision" ? "Revised" : "Original"} Asset</h3>
      <div className="project-asset-container">
        {(project.iterations?.at(-1)?.asset_text !== "" && fileId === "" && assetUrl === "") &&
          <FormattedText text={project.iterations?.at(-1)?.asset_text} />
        }
        {(fileId !== "") && (isPdf) &&
          <>
            <Document file={fileId} onLoadSuccess={handleAssetLoad}>
              <Page pageNumber={assetPagination.currentPage} renderTextLayer={false} renderAnnotationLayer={false} scale={1} />
            </Document>
            <div className="analysis-pagination">
              <button
                className="btn btn-disabled"
                type="button"
                disabled={assetPagination.currentPage <= 1}
                onClick={e => handleAssetChange(e, -1)}
              >
                Previous
              </button>
              <span>
                {assetPagination.currentPage || (assetPagination.numPages ? 1 : '--')} of {assetPagination.numPages || '--'}
              </span>

              <button
                className="btn"
                type="button"
                disabled={assetPagination.currentPage >= assetPagination.numPages}
                onClick={e => handleAssetChange(e, 1)}
              >
                Next
              </button>
            </div>
          </>
        }
        {(fileId !== "") && !(isPdf) &&
          <video className="asset-url-container" src={fileId} controls></video>
        }
        {(assetUrl !== "") &&
          <iframe className="asset-url-container"
            src={project.iterations?.at(-1)?.asset_url}>
          </iframe>
        }
      </div>
    </div>
  )
};

const ResultsBoxLabel = ({ labelText }: TLabel) => {
  if (labelText !== "") return (<p className="results-box-label">{labelText}</p>);
};

const ResultBox = ({ attr, metrics, modifier, section }: TResultBox) => {
  let scoreText = "--.-";
  let subText = "";

  if (metrics && Object.keys(metrics).length > 0) {
    attr.symbol === "$"
      ? scoreText = `$${metrics?.goals && metrics?.goals[attr.value] ? metrics?.goals[attr.value]?.score : "--.-"}`
      : scoreText = `${metrics?.goals && metrics?.goals[attr.value] ? metrics?.goals[attr.value]?.score : "--.-"}%`;

    if (metrics?.goals) {
      subText = metrics?.goals[attr.value]?.subtext ? metrics?.goals[attr.value]?.subtext : '';
    }
  };

  return (
    <div key={v4()} className="results-box flex-column">
      <p className="result-title bold">{modifier} {attr.title}</p>
      <p className="result-score bold">{scoreText}</p>
      <p className="result-subtext bold">{subText}</p>
      <ResultsBoxLabel labelText={section === "revision" ? "AI Prediction" : ""} />
    </div>
  );
};

const metricsJson = (project: any, section: string): any => {
  const metrics = (section === "revision") ? project.iterations?.at(-1)?.revision_result_json : project.iterations?.at(-1)?.result_json;
  return JSON.parse(metrics ? metrics : "{}");
};

const CreateResultBoxes = ({ project, section = '' }: TResultBoxes) => {
  const goalMetrics = metricsJson(project, section);

  const goalModifier = (section === "revision") ? "" : "";
  return project.iterations?.at(-1)?.goals.map((attr: any) => {
    if (attr.value === "Demand") {
      return (
        <>
          <div key={v4()} className="results-box flex-column">
            <p className="result-title bold">{goalModifier} Demand - Definitely</p>
            <p className="result-score bold">{goalMetrics && Object.keys(goalMetrics).length && goalMetrics?.goals && goalMetrics?.goals?.Demand ? goalMetrics?.goals?.Demand.score : "--.-"}%</p>
            <p className="result-subtext bold">{goalMetrics && Object.keys(goalMetrics).length && goalMetrics?.goals && goalMetrics?.goals[attr.value]?.subtext ? goalMetrics?.goals[attr.value]?.subtext : 'Extremely likely to purchase'}</p>
            <ResultsBoxLabel labelText={section === "revision" ? "AI Prediction" : ""} />
          </div>
          <div key={v4()} className="results-box flex-column">
            <p className="result-title bold">{goalModifier} Demand - Definitely + Probably</p>
            <p className="result-score bold">{goalMetrics && Object.keys(goalMetrics).length && goalMetrics?.goals && goalMetrics?.goals?.DemandProbably ? goalMetrics.goals.DemandProbably.score : "--.-"}%</p>
            <p className="result-subtext bold">{goalMetrics && Object.keys(goalMetrics).length && goalMetrics?.goals && goalMetrics?.goals[attr.value]?.subtext ? goalMetrics?.goals[attr.value]?.subtext : 'Extremely or very likely to purchase'}</p>
            <ResultsBoxLabel labelText={section === "revision" ? "AI Prediction" : ""} />
          </div>
        </>
      );
    } else if (attr) {
      return <ResultBox attr={attr} metrics={goalMetrics} modifier={goalModifier} section={section} />;
    } else { return '' };
  });
};

const RevisionResultBoxes = ({ project, section = '' }: TResultBoxes) => {
  const goalMetrics = metricsJson(project, section);



  const goalModifier = (section === "revision") ? "" : "";
  return (
    <>
      <div key={v4()} className="results-box flex-column">
        <p className="result-title bold">{goalModifier} Original Demand</p>
        <p className="result-score bold">{goalMetrics && Object.keys(goalMetrics).length && goalMetrics?.goals && goalMetrics?.goals?.Demand ? goalMetrics?.goals?.Demand.score : "--.-"}%</p>
      </div>
      <div key={v4()} className="results-box flex-column">
        <p className="result-title bold">{goalModifier} Predicted Demand</p>
        <p className="result-score bold">{goalMetrics && Object.keys(goalMetrics).length && goalMetrics?.goals && goalMetrics?.goals?.Demand ? goalMetrics.goals.Demand.score : "--.-"}%</p>
        <ResultsBoxLabel labelText={section === "revision" ? "AI Prediction" : ""} />
      </div>
    </>
  );
}

const OverviewParticipants = ({ project, handleRespondant, handleSwitchTab }: TProjectOverview) => {
  const responses = project.viewpoints ? [...project.viewpoints].reverse() : [];

  const filteredResponses = responses.filter((response: any) => {
    return (response.title !== "" && response.name !== "");
  });

  const displayResponses = filteredResponses?.length > 2 ? filteredResponses : responses;
  return (
    <div className="recent-participants">
      <h3>Recent Viewpoints</h3>
      <Table responsive hover className="gc-respondent-table">
        <thead key={v4()}>
          <tr key={v4()}>
            <th className="center"><FontAwesomeIcon icon="film" key={v4()} /></th>
            <th key={v4()}>Name</th>
            <th className="center" key={v4()}>Links</th>
            <th key={v4()}>Title</th>
            <th key={v4()}>Company</th>
            <th className="pill pill-lg" key={v4()}>Demand</th>
          </tr>
        </thead>
        <tbody key={v4()}>
          {displayResponses.slice(0, 4).map((respondent: any) => {
            const liClass = respondent.linkedIn ? "" : "hidden";

            return (
              <tr key={v4()}>
                <td key={v4()} className="center">{respondent.has_video && <FontAwesomeIcon icon="film" />}</td>
                <td key={v4()} onClick={handleRespondant} id={respondent.uuid} className="link bold">{respondent.name ? respondent.name : "Anonymous"}</td>
                {/* <td onClick={handleRespondant} id={respondent.uuid} className="link bold">{respondent.name ? respondent.name : "Anonymous"}</td> */}
                <td key={v4()} className="center"><a href={respondent.linkedIn} target={"_new"} className={liClass}><img className="li-icon" src={'/images/linkedin.png'} /></a></td>
                <td key={v4()}>{respondent.title}</td>
                <td key={v4()}>{respondent.company_name}</td>
                <td key={v4()} className="pill pill-lg">
                  <div className={respondent.demand.replace(/(Yes|No), /g, "").replace(/ /g, "").toLowerCase()}>{respondent.demand.replace(/(Yes|No), /g, "")}</div>
                </td>
              </tr>);
          })
          }
        </tbody>
      </Table>
      <div className="view-all link bold" data-name="viewpoints" onClick={handleSwitchTab}>{responses?.length ? "View All" : ""}</div>

    </div>
  );

}

const OverviewResultsBoxes = ({ project }: TProject) => {

  const demand = project.demand_override ?? project.stats_demand;
  const distinction = project.distinction ?? project.stats_distinction ?? 0;
  const marketSize = project.market_size ? Intl.NumberFormat('en', { notation: 'compact' }).format(project.market_size) : "--";

  return (
    <div className="flex-row results-boxes">
      <div className="display-box">
        <p className="overview-results-value">{demand ? (demand * 100).toFixed(2) : '--'}%</p>
        <p className="overview-results-title">Top Box Demand</p>
      </div>
      <div className="display-box">
        <p className="overview-results-value">{distinction > 0 ? (distinction * 100).toFixed(2) : "--"}%</p>
        <p className="overview-results-title">Distinction</p>
      </div>
      <div className="display-box">
        <p className="overview-results-value">${marketSize}</p>
        <p className="overview-results-title">Market Size</p>
      </div>
    </div>
  );
}

const ResultsOverviewContent = ({ project, handleRespondant, handleSwitchTab }: TProjectOverview) => {

  /* TODO: refactor calculations out of this compnents */
  const [ leadsData, leadsError ] = useGetLeadsData(project.id);

  const currentTime = new Date();
  const currentDay = currentTime.setHours(0, 0, 0, 0);

  const daysToCompletion = project.completion_date ? Math.ceil(((new Date(project.completion_date)).getTime() - (new Date(currentDay)).getTime()) / (1000 * 60 * 60 * 24)) : -1;

  const randomLeads: Array<number> = [];
  const totalLeads = leadsData?.leads?.length ?? 0;

  if (totalLeads) {
    for (let i = 0; i < 10; i++) {
      let nextLead = Math.floor(Math.random() * totalLeads);
      while (randomLeads.includes(nextLead)) {
        nextLead = Math.floor(Math.random() * totalLeads);
      }
      randomLeads.push(leadsData?.leads[nextLead]);
    }
  }

  const graphDemand = project.demand_override ?? project.stats_demand ?? 0;
  const graphDistinction = project.distinction ?? project.stats_distinction ?? 0;

  const dotX = graphDemand ? graphDemand * 2 * (1 - 0.1529) + .1072 - .05 : 0;
  const dotY = graphDistinction ? graphDistinction * (1 - 0.1633) + .1528 - .075 : 0;

  const showGraph = project.graph_url && project.graph_url !== "";
  const graphUrl = showGraph ? project.graph_url : "/images/2x2.png";
  const showDot = dotX && dotY;

  return (
    <>
      { /* <ProjectStatusBar project={project} /> */}
      <div className="flex-row project-overview">
        <div className="flex-column left-column">
          <div className={showGraph ? "overview-graph graph-ready" : showDot ? "overview-graph show-dot" : "overview-graph"}>
            <div className="demand-distinction-dot" style={{ left: dotX * 100 + "%", bottom: dotY * 100 + "%" }}></div>
            <div className="not-ready">
              <h4>Graphed once data is statistically significant</h4>
            </div>
            <img src={graphUrl} />
          </div>
          <OverviewResultsBoxes project={project} />
          <OverviewParticipants project={project} handleRespondant={handleRespondant} handleSwitchTab={handleSwitchTab} />

        </div>
        <div className="flex-column right-column">
          <div className="display-box overview-status">
            <h3>{projectStatus.find((status: any) => status.value === project.status)?.label}</h3>
          </div>

          <div className="display-box overview-summary">
            <AdminReloadStats project={project} />
            <h4>{project.stats_viewpoints ?? "--"} viewpoints</h4>
            <p>Collected</p>

            {(daysToCompletion >= 0) && <>
              <h4>~{daysToCompletion} days needed</h4>
              <p>For statistical significance</p>
            </>
            }

            {project.user_type !== "marketer" && <>

              <h4>{project.stats_demo_requests ?? "--"} demos</h4>
              <p>Requested</p>

              <h4>
                ${(Math.round(((project.stats_investment_amount_low + project.stats_investment_amount_high) / 2) / 1000) * 1000).toLocaleString("en-US")} soft-circled
              </h4>
              <p> ${(Math.round(project.stats_investment_amount_low / 1000) * 1000).toLocaleString("en-US")} - ${(Math.round(project.stats_investment_amount_high / 1000) * 1000).toLocaleString("en-US")} from {project.stats_angels ?? "--"} buyers</p>
            </>
            }
          </div>

          {(project.users?.length > 0 || project.invitations.filter(invitation => invitation.approval_status !== "rejected" && !invitation.is_redeemed && invitation.invitee_role === "guest").length > 0) && <ProjectGuestViewers project={project} />}

          {(totalLeads > 0) && (


            <div className="display-box overview-activity white">
              <h4>Recent Activity</h4>
              {randomLeads.map((lead: any) => {
                return (
                  <p>Prepping to ping <Link to={lead.linkedInUrl} target="_blank"><b>{lead.firstName} {lead.lastName}</b> <img className="li-icon" src={'/images/linkedin.png'} /></Link> at <b>{lead.companyName}</b></p>
                );
              })}
            </div>
          )}
        </div>
      </div>

    </>
  );
};



const ResultsConfigurationContent = ({ project, assetPagination, handleAssetLoad, handleAssetChange }: TAssetContent) => (
  <div className="projects-container">
    <ProjectsContainer project={project} assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} />
    <DetailsContainer project={project} />
  </div>
);


const ResultsContentHtml = ({ project }: TProject) => {
  if (project.iterations?.at(-1)?.result_json) {
    const resultsJson = JSON.parse(project.iterations?.at(-1)?.result_json);
    return (
      <div className="result-highlights-html flex-row">
        <div className="result-highlights-block flex-column">
          <h4>Top Strengths</h4>
          <ul>
            {resultsJson.insights.strengths?.map((strength: string) => {
              return (
                <li>{strength}</li>
              )
            })}
          </ul>
        </div>
        <div className="result-highlights-block flex-column">
          <h4>Top Concerns</h4>
          <ul>
            {resultsJson.insights.concerns?.map((concern: string) => {
              return (
                <li>{concern}</li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
};

const ResultsAnalysisContent = ({ project }: TProject) => (
  <iframe
    src={project.iterations?.at(-1)?.analysis_url}
    style={{
      width: "1px",
      minWidth: "100%",
    }}
    frameBorder="0"
  >
  </iframe>
);

const ResultsSummaryContent = ({ project, summaryPagination, handleSummaryLoad, handleSummaryChange }: TResultSummaryContent) => (
  <>
    <Document file={project.iterations?.at(-1)?.summary_url} onLoadSuccess={handleSummaryLoad}>
      <Page pageNumber={summaryPagination.currentPage} renderTextLayer={false} renderAnnotationLayer={false} scale={1.6} />
    </Document>
    <div className="analysis-pagination">
      <button
        className="btn btn-disabled"
        type="button"
        disabled={summaryPagination.currentPage <= 1}
        onClick={e => handleSummaryChange(e, -1)}
      >
        Previous
      </button>
      <span>
        {summaryPagination.currentPage || (summaryPagination.numPages ? 1 : '--')} of {summaryPagination.numPages || '--'}
      </span>

      <button
        className="btn"
        type="button"
        disabled={summaryPagination.currentPage >= summaryPagination.numPages}
        onClick={e => handleSummaryChange(e, 1)}
      >
        Next
      </button>
    </div>
  </>
);


export const OverviewContainer = ({ project, viewerRole, tab, respondent, handleRespondant, handleSwitchTab, summaryPagination, handleSummaryLoad, handleSummaryChange, assetPagination, handleAssetLoad, handleAssetChange }: TOverviewContainer) => (
  <>
    <div className="project-field-container">
      <div className="results-container flex-column">
        <ResultsContent project={project} viewerRole={viewerRole} tab={tab} handleSwitchTab={handleSwitchTab} respondent={respondent} handleRespondant={handleRespondant} summaryPagination={summaryPagination} handleSummaryLoad={handleSummaryLoad} handleSummaryChange={handleSummaryChange} assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} />
      </div>
    </div>
  </>
);

const SidebarTabs = ({ viewerRole, hasSummary, hasRespondents, hasAnalysis, hasInsights, hasSuggestions, hasRevision, projectType, tab, handleSwitchTab }: TResultsTabs) => {

  // The order of items in this array determine the order they show up on the page

  const tabs = [];

  if (viewerRole === "guest") {
    tabs.push(
      {
        name: "Viewpoints",
        icon: <FontAwesomeIcon icon="users" />,
        enabled: hasRespondents,
        active: tab.toLowerCase() === "viewpoints"
      },
      {
        name: "Analysis",
        icon: <FontAwesomeIcon icon="magnifying-glass-chart" />,
        enabled: hasAnalysis,
        active: tab.toLowerCase() === "analysis"
      }
    )
  } else if (viewerRole === "contributor" || viewerRole === "admin") {

    tabs.push(
      {
        name: "Overview",
        icon: <FontAwesomeIcon icon="home" />,
        enabled: true,
        active: tab.toLowerCase() === "overview"
      },
      {
        name: "Viewpoints",
        icon: <FontAwesomeIcon icon="users" />,
        enabled: hasRespondents,
        active: tab.toLowerCase() === "viewpoints"
      },
      {
        name: "Analysis",
        icon: <FontAwesomeIcon icon="magnifying-glass-chart" />,
        enabled: hasAnalysis,
        active: tab.toLowerCase() === "analysis"
      }
    );

    if (projectType !== "marketing") {
      tabs.push({
        name: "Exec Summary",
        icon: <FontAwesomeIcon icon="chart-line" />,
        enabled: hasSummary,
        active: tab.toLowerCase() === "exec summary"
      })
    }

    if (projectType === "marketing") {
      tabs.push(
        {
          name: "Insights",
          icon: <FontAwesomeIcon icon="lightbulb" />,
          enabled: hasInsights,
          active: tab.toLowerCase() === "insights"
        },
        {
          name: "Suggestions",
          icon: <FontAwesomeIcon icon="pen-to-square" />,
          enabled: hasSuggestions,
          active: tab.toLowerCase() === "suggestions"
        },
        {
          name: "Revision",
          icon: <FontAwesomeIcon icon="wand-magic-sparkles" />,
          enabled: hasRevision,
          active: tab.toLowerCase() === "revision"
        }
      );
    }

    tabs.push({
      name: "Deal Setup",
      icon: <FontAwesomeIcon icon="gear" />,
      enabled: true,
      active: tab.toLowerCase() === "deal setup"
    });
  }


  return (
    <div>
      {tabs.map(tab => {
        return (
          <p key={v4()} data-name={tab.name.toLowerCase()} className={(tab.active ? " bold" : "") + (tab.enabled ? " enabled" : " disabled")} onClick={tab.enabled ? handleSwitchTab : null}>{tab.icon}<span className="sidebar-label">&nbsp;&nbsp;{tab.name}</span></p>
        )
      })}
    </div>
  )
};

const ResultsContent = ({ project, tab, viewerRole, handleSwitchTab, respondent, handleRespondant, summaryPagination, handleSummaryLoad, handleSummaryChange, assetPagination, handleAssetLoad, handleAssetChange }: TOverviewContainer) => {
  switch (tab) {
    case "overview":
      return <ResultsOverviewContent project={project} handleRespondant={handleRespondant} handleSwitchTab={handleSwitchTab} />;
    case "deal setup":
      return <ResultsConfigurationContent project={project} assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} />;
    case "exec summary":
      return <ResultsSummaryContent project={project} summaryPagination={summaryPagination} handleSummaryLoad={handleSummaryLoad} handleSummaryChange={handleSummaryChange} />;
    case "analysis":
      return <ResultsAnalysisContent project={project} />;
    case "viewpoints":
      return <Viewpoints viewerRole={viewerRole} handleRespondant={handleRespondant} project={project} respondent={respondent} />;
    default:
      return <ResultsOverviewContent project={project} handleRespondant={handleRespondant} handleSwitchTab={handleSwitchTab} />;
  };
};

export const ProjectSidebar = ({ project, viewerRole, tab, respondent, handleRespondant, handleSwitchTab, summaryPagination, handleSummaryLoad, handleSummaryChange, aiResults }: TSidebarContainer) => (
    <SidebarTabs viewerRole={viewerRole} hasSummary={project.iterations?.at(-1)?.summary_url ? true : false} hasRespondents={project.viewpoints?.length > 0} hasAnalysis={project.iterations?.at(-1)?.analysis_url ? true : false} hasInsights={aiResults?.insights && aiResults?.insights !== ""} hasSuggestions={aiResults?.suggestions && aiResults?.suggestions !== ""} hasRevision={(project.iterations?.at(-1)?.revision_text || /(```json|```)/.test(aiResults?.revisions[aiResults?.revisions?.length - 1])) ? true : false} projectType={project.user_type === "investor" || project.user_type === "entrepreneur" ? "investor" : "marketing"} tab={tab} handleSwitchTab={handleSwitchTab} />
);

const RevisionTabs = ({ hasPdf, tab, handleSwitchTab }: TRevisionTab) => {
  const states = hasPdf ? ["Predicted Performance", "Simulations"] : ["Predicted Performance"];
  const html = states.map(state => {
    const isBold = tab === state;
    return <p key={v4()} className={isBold ? "bold text-lg" : "text-lg"} onClick={handleSwitchTab}>{state}</p>
  });

  return (
    <div className="results-tab-container flex-row">
      {html}
    </div>
  )
};

const ProjectStatusBar = ({ project }: TProject) => {
  let statusClassName = "complete";
  const html = projectStatus.map((status) => {
    const isBold = status.value === project.status;
    if (isBold) statusClassName = "current";
    else if (statusClassName === "current") statusClassName = "future";

    return (<li key={v4()} className={statusClassName}>{status.label}</li>);
  });
  return (
    <ul className="project-status-steps step-menu">
      {html}
    </ul>
  );
};

const RevisionDetailsContainerVerbose = ({ project, highlightedTitle, handleRevisionMouse }: TRevisionDetailsVerbose) => (
  <div className="project-field-side-container">
    <h5>&nbsp;</h5>
    {metricsJson(project, "revision")?.revisions?.map((revision: any, index: number) => (
      <div id={"highlightedIndex" + index} className={revision.title === highlightedTitle ? "revision-objection-card active" : "revision-objection-card"} onMouseEnter={e => handleRevisionMouse(e, "over")} onMouseLeave={(e) => handleRevisionMouse(e, "out")}>
        <h5 key={v4()} className="revision-title">{revision.title}</h5>
        <p>{revision.description}</p>
      </div>
    ))}
  </div>
);

const AiRevisionDetailsContainerVerbose = ({ project, revisions, highlightedTitle, handleRevisionMouse }: TAiRevisionDetailsVerbose) => (
  <div className="project-field-side-container highlighter">
    {revisions?.map((revision: any, index: number) => (
      <div id={"highlightedIndex" + index} className={revision.title === highlightedTitle ? "revision-objection-card active" : "revision-objection-card"} onMouseEnter={e => handleRevisionMouse(e, "over")} onMouseLeave={(e) => handleRevisionMouse(e, "out")}>
        <h5 key={v4()} className="revision-title">{revision.title}</h5>
        <p>{revision.description}</p>
      </div>
    ))}
  </div>
);


const RevisionResultsContent = ({ project, tab }: TRevisionResults) => {
  switch (tab) {
    case "Predicted Performance":
      return <RevisionResultsPerformanceContent project={project} />;
    case "Simulations":
      return <RevisionResultsSimulationContent project={project} />;
    default:
      return <></>;
  };
};

const RevisionResultsPerformanceContent = ({ project }: TProject) => (
  <>
    <div className="flex-row">
      <RevisionResultBoxes project={project} section={"revision"} />
    </div>
  </>
);

const RevisionResultsSimulationContent = ({ project }: TProject) => (
  <>
    <div className="result-highlights-html">
      <h5 className="result-title">Revision Simulations</h5>
      <p>AI simulations predicted how multiple revisions would perform with your target audience. The best performing one is shown below.</p>
      {(project.iterations?.at(-1)?.revision_result_json?.insights) ? <RevisionResultsContentHtml /> : (project.iterations?.at(-1)?.revision_url) ? <RevisionResultsContentPdf project={project} /> : ""}
    </div>
  </>
);

const RevisionResultsContentPdf = ({ project }: TProject) => (
  <Document file={project.iterations?.at(-1)?.revision_url} >
    <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} scale={1} />
  </Document>
);

const RevisionResultsContentHtml = () => (
  <>
    hi
  </>
);

const RevisionResultsContainer = ({ project, tab, handleSwitchTab }: TRevisionContainer) => (
  <div className="project-field-container">
    <RevisionTabs hasPdf={project.iterations?.at(-1)?.revision_url ? true : false} tab={tab} handleSwitchTab={handleSwitchTab} />
    <div className="results-container flex-column">
      <RevisionResultsContent project={project} tab={tab} />
    </div>
  </div>
);

const AiRevisionResultsContainer = ({ project, aiResults }: TAiRevision) => {
  const section = "revision";
  const goalMetrics = metricsJson(project, section);


  const originalDemand = new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(aiResults?.performance?.topBox);


  const goalModifier = (section === "revision") ? "" : "";
  return (

    <div className="project-field-container">
      <h3 className="underlined">Performance</h3>
      <div className="results-container flex-column">
        <div className="flex-row">

          <div key={v4()} className="results-box flex-column">
            <p className="result-title bold">{goalModifier} Original Demand</p>
            <p className="result-score bold">{originalDemand}</p>
          </div>
          <div key={v4()} className="results-box flex-column">
            <p className="result-title bold">{goalModifier} Predicted Demand</p>
            <p className="result-score bold">{goalMetrics && Object.keys(goalMetrics).length && goalMetrics?.goals && goalMetrics?.goals?.Demand ? goalMetrics.goals.Demand.score : "--.-"}%</p>
            <ResultsBoxLabel labelText={section === "revision" ? "AI Prediction" : ""} />
          </div>
        </div>
      </div>
    </div>
  );
}

const NewProjectFromRevisionButton = ({ status, handleCreateIteration }: TNewProject) => {
  return status === "firm_investment" ? (
    <div className="new-iteration-wrapper">
      <h4>Kick off another iteration for actual performance numbers and additional improvements.</h4>
      <div className="btn bold" onClick={handleCreateIteration}>Configure Next Iteration</div>
    </div>
  ) : <></>;
};

const RevisionContent = ({ project, tab, highlights, highlightedTitle, highlightedIndex, handleRevisionMouse, handleCreateIteration, handleSwitchTab }: TRevisionContent) => (
  <>
    <RevisionResultsContainer project={project} tab={tab} handleSwitchTab={handleSwitchTab} />
    <div className="projects-container revision">
      <div className="project-container">
        <h5 className="bold">Revised Content</h5>
        <div className="project-asset-container">
          <FormattedText text={project.iterations?.at(-1)?.revision_text} highlights={highlights} highlightedIndex={highlightedIndex} />
        </div>
      </div>
      <RevisionDetailsContainerVerbose project={project} highlightedTitle={highlightedTitle} handleRevisionMouse={handleRevisionMouse} />
    </div>
    <NewProjectFromRevisionButton status={project.status} handleCreateIteration={handleCreateIteration} />
  </>
);

const InsightsContent = ({ aiResults }: TAiContent) => {
  const data = JSON.parse(aiResults.insights.replace(/(```json|```)/g, ""));
  const feedbackData = JSON.parse(aiResults.responseJson);
  return (
    <div className="project-container">
      <h3>Market Insights</h3>
      <div className="results-output project-field-side-container">
        <p>Feedback was collected from {feedbackData.responses.length} users. Here's what they had to say:</p>
        <strong>What They Liked Most</strong>
        <ol>
          {data["Stack Ranked Benefits"].map((entry: any) => {
            return (
              <li><strong>{entry.title}</strong><br />{entry.description}</li>
            );
          })}
        </ol>
        <strong>Their Top Concerns</strong>
        <ol>
          {data["Specific Concerns"].map((entry: any) => {
            return (
              <li><strong>{entry.title}</strong><br />{entry.description}</li>
            );
          })}
        </ol>
        <strong>Other Concerns They Mentioned</strong>
        <ol>
          {data["General Concerns"].map((entry: any) => {
            return (
              <li><strong>{entry.title}</strong><br />{entry.description}</li>
            );
          })}
        </ol>
      </div>
    </div>
  )
}

export const InsightsContainer = ({ project, assetPagination, handleAssetLoad, handleAssetChange, aiResults }: TAiContainer) => {
  return (
    <>
      <p className="ai-chain-subtitle">The left panel shows the original asset shown to users, the right panel shows their feedback.</p>
      <div className="projects-container ai-chain">
        <AiProjectsContainer project={project} assetType="original" assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} />
        <InsightsContent aiResults={aiResults} />
      </div>
    </>
  )
}

const SuggestionsContent = ({ aiResults }: TAiContent) => {
  const data = JSON.parse(aiResults.suggestions?.replace(/(```json|```)/g, ""));
  return (
    <div className="project-container">
      <h3>Suggested Edits and Ideas</h3>

      <div className="results-output project-field-side-container">
        <p>These are suggestions on how to reinforce the strengths and address the concerns identified by market feedback in order to {aiResults.goalText.replace(/, /g, " and ").toLowerCase()}.</p>
        <div>
          {Object.keys(data).map((key: any) => {
            return (
              <div>
                <strong>{key}</strong>
                <ol>
                  {data[key].map((entry: any) => {
                    return (
                      <li><strong>{entry.title}</strong><br />{entry.description}</li>
                    );
                  })}
                </ol>
              </div>
            )
          })}
        </div>
      </div>
    </div>

  )
}

export const SuggestionsContainer = ({ project, assetPagination, handleAssetLoad, handleAssetChange, aiResults }: TAiContainer) => {
  return (
    <>
      <p className="ai-chain-subtitle">The left panel shows the original asset show to users, the right panel shows suggestions that incorporate their feedback.</p>
      <div className="projects-container ai-chain">
        <AiProjectsContainer project={project} assetType="original" assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} />
        <SuggestionsContent aiResults={aiResults} />
      </div>
    </>
  )
}


export const AiRevisionContainerB = ({ project, aiResults, tab, highlights, highlightedTitle, highlightedIndex, handleRevisionMouse, handleCreateIteration, handleSwitchTab }: TAiRevisionContainer) => {
  const data = JSON.parse(aiResults.revisions[aiResults.revisions.length - 1]?.replace(/(```json|```)/g, ""));
  return (
    <div className="project-field-container revision-container">
      <RevisionResultsContainer project={project} tab={tab} handleSwitchTab={handleSwitchTab} />
      <div className="projects-container revision ai-chain">
        <div className="project-container">
          <h5 className="bold">Revised Content</h5>
          <div className="project-asset-container">
            <FormattedText text={data.text} highlights={highlights} highlightedIndex={highlightedIndex} />
          </div>
        </div>
        <AiRevisionDetailsContainerVerbose project={project} revisions={data.revisions} highlightedTitle={highlightedTitle} handleRevisionMouse={handleRevisionMouse} />
      </div>
      <NewProjectFromRevisionButton status={project.status} handleCreateIteration={handleCreateIteration} />
    </div>
  )
}

export const AiRevisionContainer = ({ project, aiResults, tab, highlights, highlightedTitle, highlightedIndex, handleRevisionMouse, handleCreateIteration, handleSwitchTab, assetPagination, handleAssetLoad, handleAssetChange }: TAiRevisionContainer) => {
  const data = JSON.parse(aiResults.revisions[aiResults.revisions.length - 1]?.replace(/(```json|```)/g, ""));
  return (
    <>
      <p className="ai-chain-subtitle">The left panel shows the original asset, the right panel shows the revised asset.</p>
      <div className="projects-container ai-chain">
        <AiProjectsContainer project={project} assetType="original" assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} />
        <AiProjectsContainer project={project} assetType="revision" assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} />
      </div>
      <div className="project-field-container revision-container">
        <AiRevisionResultsContainer project={project} aiResults={aiResults} />
        <h3 className="underlined">Revised Content</h3>

        <div className="projects-container revision ai-chain">
          <div className="project-container">
            <div className="project-asset-container">
              <FormattedRevisionText text={data.text} highlights={highlights} highlightedIndex={highlightedIndex} />
            </div>
          </div>
          <div className="project-container">
            <AiRevisionDetailsContainerVerbose project={project} revisions={data.revisions} highlightedTitle={highlightedTitle} handleRevisionMouse={handleRevisionMouse} />
          </div>
        </div>
        <NewProjectFromRevisionButton status={project.status} handleCreateIteration={handleCreateIteration} />
      </div>
    </>
  )
}

export const RevisionContainer = ({ project, tab, highlights, highlightedTitle, highlightedIndex, handleRevisionMouse, handleCreateIteration, handleSwitchTab }: TRevisionContent) => {
  if (project.iterations?.at(-1)?.revision_text) {
    return (
      <div className="project-field-container revision-container">
        {project.iterations?.at(-1)?.revision_text ? <RevisionContent project={project} tab={tab} highlights={highlights} highlightedTitle={highlightedTitle} highlightedIndex={highlightedIndex} handleRevisionMouse={handleRevisionMouse} handleCreateIteration={handleCreateIteration} handleSwitchTab={handleSwitchTab} /> : <p></p>}
      </div>
    )
  }
};

export const DeleteProjectButton = ({ status, handleArchive, handleDelete }: TDeleteProject) => (
  <>
    {status === "archive" ? <div className="separator btn-danger mb-4"></div> : ''}
    <div className="flex-column text-center">
      {status !== "archive" ? <div className="btn btn-secondary archive bold mb-5" onClick={handleArchive}>Archive Project</div> : ''}
      {status === "archive" ? <div className="btn btn-danger delete bold mb-5" onClick={handleDelete}>Delete Project</div> : ''}
    </div>
  </>
);