import { Dialog, Typography, Box, Button } from "@mui/material"

export const ConfirmationModal = ({ open, onClose, onConfirm, success, title, message }: any) => {


    return (
        <Dialog open={open} onClose={onClose}>

            {success &&
                <>
                    <Dialog open={open} onClose={onClose}>
                        <Typography variant="h5">{title}</Typography>
                        <Typography variant="body1">{message}</Typography>
                        <Box className="flex-row" sx={{ width: "100%", gap: "20px" }}>
                            <Button onClick={onClose} variant="contained" color="primary">OK</Button>
                        </Box>
                    </Dialog>
                </>
            }

            {!success &&
                <>
                    <Typography variant="h5">{title}</Typography>
                    <Typography variant="body1">{message}</Typography>
                    <Box className="flex-row" sx={{ width: "100%", gap: "20px" }}>
                        <Button onClick={onConfirm} variant="contained" color="primary">Yes</Button>
                        <Button onClick={onClose} variant="outlined" color="primary">No</Button>
                    </Box>
                </>
            }

        </Dialog>
    )
}