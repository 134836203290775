import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { inviteProjectMember } from "../../services/gutcheck-admin-api.service";

type InvitationFormProps = {
    invitee_name: string,
    invitee_role: string,
    invitee_email: string,
    invitee_organization: string,
    source_user_email: string,
    note: string
};

const initialInvitationForm: InvitationFormProps = {
    invitee_name: "",
    invitee_role: "contributor",
    invitee_email: "",
    invitee_organization: "",
    source_user_email: "",
    note: ""
}

const role = [
    { value: "admin", label: "Admin" },
    { value: "contributor", label: "Contributor" },
    { value: "guest", label: "Guest" }
];

export const InviteMember = ({ project, open, setOpen, reloadProjectMembers }: any) => {
    const { getAccessTokenSilently } = useAuth0();

    const [invitationForm, setInvitationForm] = useState<InvitationFormProps>({
        ...initialInvitationForm,
        note: `I'm excited to start generating opportunities for ${project.name}. Please accept the invite and help me complete the setup.`
    });

    const handleInvite = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await inviteProjectMember(project.id, invitationForm, accessToken);

        if (data) {
            setInvitationForm(initialInvitationForm);
            reloadProjectMembers();
            setOpen(false);
        } else {
            console.error(error);
        }
    };

    const [editMode, setEditMode] = useState(false);

    const handleChange = (e: any) => {
        setInvitationForm({
            ...invitationForm,
            [e.target.name]: e.target.value,
        });
    };

    const handleCancel = () => {
        setInvitationForm(initialInvitationForm);
        setOpen(false);
    }


    return (
        <Dialog open={open} onClose={handleCancel}>
            <Typography variant="h5">Invite Member</Typography>
            <Box>
                <Stack spacing={2} sx={{ width: "100%" }}>
                    <TextField
                        name="invitee_name"
                        id="invitee_name"
                        label="Invitee Name"
                        size="small"
                        value={invitationForm.invitee_name}
                        onChange={(e) => handleChange(e)}
                    />
                    <TextField
                        name="invitee_email"
                        id="invitee_email"
                        label="Invitee Email"
                        size="small"
                        value={invitationForm.invitee_email}
                        onChange={(e) => handleChange(e)}
                    />
                    <TextField
                        name="invitee_organization"
                        id="invitee_organization"
                        label="Organization"
                        select
                        size="small"
                        value={invitationForm.invitee_organization}
                        onChange={(e) => handleChange(e)}
                    >

                        <MenuItem value={project.organization.name}>{project.organization.name}</MenuItem>
                        <MenuItem value={project.name}>{project.name}</MenuItem>
                    </TextField>
                    <TextField
                        select
                        name="invitee_role"
                        id="invitee_role"
                        size="small"
                        label="Role"
                        value={invitationForm.invitee_role}
                        onChange={handleChange}
                    >
                        {role.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="source_user_email"
                        id="source_user_email"
                        label="Sender Email"
                        size="small"
                        value={invitationForm.source_user_email}
                        onChange={(e) => handleChange(e)}
                    />

                    <TextField
                        name="note"
                        id="note"
                        label="Note"
                        multiline
                        rows={2}
                        value={invitationForm.note}
                        onChange={(e) => handleChange(e)}
                    />
                </Stack>


            </Box>
            <Button variant="contained" onClick={handleInvite}>Invite</Button>
            <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
        </Dialog>
    );
}