import { useState } from "react";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Backdrop, Box, Button, Menu, MenuItem, styled } from "@mui/material";
import { DealShareButton } from "./sharing/DealShareButton";


export const DealTitle = ({ viewerRole, project, section, subheading, showShareButton = true }: any) => {

    return (
        <Box sx={{ marginBottom: "40px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
            <Box>
                <DocumentTitle pageTitle={`${project?.name} - ${section}`} />
                <h2>{section}</h2>
                {subheading && <div>{subheading}</div>}
            </Box>
            { viewerRole !== "guest" && showShareButton && <DealShareButton project={project} />}
        </Box>
    );
}