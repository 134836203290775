import { useGetProjectMembers } from "../../../../hooks/useGetProjectMembers";
import { useGetProjectInvitations } from "../../../../hooks/useGetProjectInvitations";
import { Box, styled, TableContainer, Typography, Table, TableHead, TableRow, TableBody, TableCell, FormControl, FormControlLabel, Switch, TextField, Button, Dialog } from "@mui/material";
import { DealMembersTable } from "./DealMembersTable";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../../modules/ClientProvider";
import { DealRequests } from "./DealRequests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SharingControls } from "./DealSharingControls";
import Loading from "../../../../components/Loading";

const DealGuestsBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginBottom: "40px"
});


export const DealGuests = ({ project, viewerRole }: any) => {

    const membersData = useGetProjectMembers(project.id ?? "");
    const invitationsData = useGetProjectInvitations(project.id ?? "");
    const projectClient = ClientProvider.provideProjectClient();
    const { getAccessTokenSilently } = useAuth0();

    const [settingsActive, setSettingsActive] = useState(false);

    const [projectShareLink, setProjectShareLink] = useState(project.project_share_links[0]);

    useEffect(() => {

        const fetchShareLink = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await projectClient.getProjectShareLink(project.id, project.project_share_links[0].id, accessToken);

            if (data) {
                setProjectShareLink(data.projectShareLink);
            } else {
                console.error(error);
            }
        }

        fetchShareLink();
    }, []);

    const members = membersData?.members?.filter((member: any) => member.role === "guest");
    const pendingInvitations = invitationsData?.invitations?.filter((invitation: any) => !invitation.is_redeemed && (invitation.approval_status === "pending") && (invitation.invitee_role === "guest"));
    const approvedInvitations = invitationsData?.invitations?.filter((invitation: any) => !invitation.is_redeemed && (invitation.approval_status === "sent") && (invitation.invitee_role === "guest"));

    const shareLink = project.project_share_links[0];

    const visibleExecutives = projectShareLink.filter_interest_demo || projectShareLink.filter_interest_invest || projectShareLink.filter_interest_advise ? "Engaged" : "All";
    const sections = [];
    if (projectShareLink.display_prospects) sections.push("Prospects");
    if (projectShareLink.display_investors) sections.push("Angels");
    if (projectShareLink.display_viewpoints) sections.push("Viewpoints");
    if (projectShareLink.display_analysis) sections.push("Analysis");

    const handleSettingsClose = async () => {
        setSettingsActive(false);
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.getProjectShareLink(project.id, project.project_share_links[0].id, accessToken);

        if (data) {
            setProjectShareLink(data.projectShareLink);
        } else {
            console.error(error);
        }
    }

    return (
        <>
            <Box className="" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px", alignItems: "center", marginBottom: "20px" }}>
                <Typography variant="body1">External Guest Viewers can see {
                    sections.map((section, index) => {
                        return (
                            <span key={`${section}-${index}`}>
                                <strong key={index}>{section}</strong>
                                {index === sections.length - 1 ? "" : (index === sections.length - 2 ? " and " : ", ")}
                            </span>
                        )
                    })
                } from <strong>{visibleExecutives}</strong> Executives.</Typography>
                {viewerRole === "admin" &&
                    <Box>
                        <Button variant="outlined" size="small" color="primary" onClick={() => setSettingsActive(true)}><FontAwesomeIcon icon="gear" />&nbsp;&nbsp;Guest Settings</Button>
                    </Box>
                }

            </Box>

            {(!membersData.fetched || !invitationsData.fetched) &&
                <Box sx={{ paddingTop: "80px"}}>
                    <Loading />
                </Box>
            }

            {membersData.fetched && invitationsData.fetched &&
                <DealGuestsBox>
                    <DealMembersTable membersType="guests" viewerRole={viewerRole} project={project} members={members} pendingInvitations={pendingInvitations} invitees={approvedInvitations} reloadMembers={membersData.reloadProjectMembers} reloadInvitations={invitationsData.reloadProjectInvitations} />
                </DealGuestsBox>
            }

            {viewerRole === "admin" &&
                <Dialog open={settingsActive} onClose={handleSettingsClose}>
                    <SharingControls project={project} viewerRole={viewerRole} active={settingsActive} setActive={setSettingsActive} />
                </Dialog>
            }


        </>
    );
}
