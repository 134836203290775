import { border, styled, width } from "@mui/system";
import CollaborativeEditor from "../../../../components/CollaborativeEditor";
import TextEditor from "../../../../components/TextEditor"
import { Box } from "@mui/material";
import { useState } from "react";

const EditorBox = styled(Box)({
    aspectRation: '25/9',
    width: '100%',
    "& .ProseMirror": {
        aspectRatio: '25/9',
        width: '100%',
        border: '1px solid #c9c9c9',
        backgroundColor: "#ffffff",
        padding: '1rem',
    }

});

export const DealSetupQuestions = ({ project, user }: any) => {

    const [keyQuestionsText, setKeyQuestionsText] = useState<any>(project.icp || {});

    return (
        <Box>
            <p>List 3-5 key things you'd like to learn from buyers.</p>
            <EditorBox>
                <CollaborativeEditor documentName={`${project.organization.tag}/${project.tag}/key_questions`} form={keyQuestionsText} setForm={setKeyQuestionsText} />
            </EditorBox>
        </Box>
    )
}