import { Box, Dialog, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react";
import { addProjectMember, getUserByEmailAddress } from "../../services/gutcheck-admin-api.service";
import { useState } from "react";

const roles = [
    { value: "admin", label: "Admin" },
    { value: "contributor", label: "Team Member" },
    { value: "guest", label: "Guest" },
];



export const AddProjectMember = ({ project, reloadProjectMembers, open, setOpen}: any) => {

    const { getAccessTokenSilently } = useAuth0();
    const [ memberForm, setMemberForm ] = useState<{ user_id: number, email_address: string; name: string; role: string; }>({
        user_id: -1,
        email_address: "",
        name: "",
        role: ""
    })
    const [ lookupMessage, setLookupMessage ] = useState("");
    

    const handleChange = (e: any) => {
        setMemberForm({
            ...memberForm,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const accessToken = await getAccessTokenSilently();
         const { data, error } = await addProjectMember(project.id, memberForm.user_id, memberForm.role, accessToken);

         if (data) {
             reloadProjectMembers();
             setOpen(false);
         }
    }

    const handleClose = () => {
        setMemberForm({
            user_id: -1,
            email_address: "",
            name: "",
            role: "",
        });
        setOpen(false);
    }

    const handleLookup = async (e: any) => {
        e.preventDefault();
        const accessToken = await getAccessTokenSilently();
        const {data, error} = await getUserByEmailAddress(memberForm.email_address, accessToken);
        if (data?.user) {
            setMemberForm({
                ...memberForm,
                name: data.user.name,
                user_id: data.user.id
            });
            console.log(data);
            setLookupMessage("User found: " + data.user.id);
        } else {
            setLookupMessage("User not found");
        }
    }

    return (
        <Dialog open={open}>
            <h5>Add Existing User</h5>
            <form>
                <Stack spacing={2}>
                    <TextField
                        id="email_address"
                        name="email_address"
                        label="Email"
                        value={memberForm.email_address || ""}
                        onChange={handleChange}
                    />
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography>{lookupMessage}</Typography>
                        <Typography className="link" onClick={handleLookup}>Lookup</Typography>
                    </Box>
                    <TextField
                        disabled={memberForm.user_id < 0}
                        id="name"
                        name="name"
                        label="Name"
                        value={memberForm.name || ""}
                        onChange={handleChange}
                    />
                    <TextField
                        disabled={memberForm.user_id < 0}
                        select
                        id="role"
                        name="role"
                        label="Role"
                        value={memberForm.role || ""}
                        onChange={handleChange}
                    >
                        {roles.map((role) => (
                            <MenuItem key={role.value} value={role.value}>
                                {role.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <button disabled={memberForm.user_id < 0} type="button" onClick={handleSubmit}>Add Member</button>
                    <button type="button" className="btn-secondary" onClick={handleClose}>Cancel</button>
                </Stack>
            </form>
        </Dialog>
    )

}