import { Box, Modal, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { getInvestmentMaterials } from "../../../core/services/gutcheck-api.service";
import { useEffect, useState } from "react";
import useAnalytics from "../../../hooks/useAnalytics";

const docsendModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '90%',
    aspectRatio: '4/3'
};

export const InvestmentMaterials = ({ surveyResponse }: any) => {

    const [investmentMaterials, setInvestmentMaterials] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalDocument, setModalDocument] = useState("");
    const { trackSurveyResponsePage } = useAnalytics();

    const project = surveyResponse.survey_link.project;

    const handleClose = () => {
        setOpenModal(false);
    }

    const handleOpen = (doc: string) => {
        setModalDocument(doc);
        //setOpenModal(true);
        trackSurveyResponsePage(surveyResponse, 'expert flow - view document', { document: doc });
        window.open(`${doc}?email=${surveyResponse.viewpoint?.email ?? surveyResponse.email_address}`, '_blank');
    }

    useEffect(() => {
        const fetchInvestmentMaterials = async () => {
            const { data, error } = await getInvestmentMaterials(surveyResponse.public_id);
            setInvestmentMaterials(data.investmentMaterials);
        }
        fetchInvestmentMaterials();
    }, []);

    if (!investmentMaterials || investmentMaterials.length === 0) return <></>;
    
    return (
        <div className="investment-materials">
            <h5>Investment Materials</h5>
            <TableContainer>
                <Table>
                    <TableBody>

                        {investmentMaterials.map((investmentMaterial: any) => (
                            <TableRow key={investmentMaterial.uuid}>
                                <TableCell sx={{ cursor: "pointer"}}><div className="document-placeholder" onClick={() => handleOpen(investmentMaterial.asset_url)}><img className="document-preview" src={investmentMaterial.thumbnail_url} /></div></TableCell>
                                <TableCell>
                                    <h5>{investmentMaterial.title}</h5>
                                    <p>{investmentMaterial.description}<br />
                                        <span className="link" onClick={() => handleOpen(investmentMaterial.asset_url)}>View</span></p>
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <Modal open={openModal} onClose={handleClose}>
                <Box sx={docsendModalStyle}>
                    <iframe className="investor-deck" src={modalDocument} allow="fullscreen"></iframe>
                </Box>
            </Modal>
        </div>
    );
}