import { Link, useOutletContext } from "react-router-dom"
import { EvaluationSummary } from "../components/EvaluationSummary"
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import { getViewpointStats, updateSurveyReponse } from "../../../core/services/gutcheck-api.service";
import { InvestmentActivity } from "../components/InvestmentActivity";
import { Document, Page } from "react-pdf";
import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography, Modal, Box, FormControl, FormControlLabel, Switch, TextField, FormLabel, Table, TableHead, TableContainer, TableRow, TableCell, Tab, TableBody } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TopReservationActivity } from "../components/TopReservationActivity";
import { DealUpdates } from "../components/DealUpdates";
import { InvestmentMaterials } from "../components/InvestmentMaterials";
import { SpvFaq } from "../components/SpvFaq";
import useAnalytics from "../../../hooks/useAnalytics";

type SpvStatus = {
    pending: string;
    approved: string;
    open: string;
    closed: string;
};

const spvStatus: SpvStatus = {
    pending: "Awaiting Greenlight",
    approved: "In Process",
    open: "Open for Co-Investment",
    closed: "Closed"
};

const ContextMessage = ({ surveyResponse, handleLearnMore, viewpointStats }: any) => {

    const project = surveyResponse.survey_link.project;

    let invitationRate = 0;

    if (viewpointStats?.totalViewpoints > 0) {
        invitationRate = Math.round((viewpointStats.approvedViewpoints / viewpointStats.totalViewpoints) * 100);
    }

    if (surveyResponse.survey_link?.project?.spv_stage === "approved") {
        return (
            <div className="deal-page-intro">
                <h5>It's time to reserve your spot.</h5>
                <p>{project.organization.name} has greenlit an investment in {project.name}.
                    Your viewpoint was one of the key viewpoints that informed the investment decision and/or company strategy
                    and you've been offered an opportunity to co-invest or advise alongside {project.organization.name}. <Link className="bold" to="#" onClick={handleLearnMore}>Learn more &raquo;</Link></p>
            </div>
        )
    } else if (surveyResponse.survey_link?.project?.spv_stage === "open") {
        return (
            <div className="deal-page-intro">
                <h5>The investment round is now open.</h5>
                <p>Click below to view {project.name}'s investor deck and a summary of terms for the round.<br /><Link className="bold" to="#" onClick={handleLearnMore}>Learn more &raquo;</Link></p>
            </div>
        )
    } else if (surveyResponse.survey_link?.project?.spv_stage === "closed_funded") {
        return (
            <div className="deal-page-intro">
                <h5>The investment round is now closed.</h5>
                <p>BrightLoop and {project.organization.name} completed their investment in {project.name}.</p>
            </div>
        )
    }
}

const ReservationDetails = ({ surveyResponse, displaySpvDetails, setDisplaySpvDetails }: any) => {
    const project = surveyResponse.survey_link.project;
    const { trackSurveyResponseEvent } = useAnalytics();

    const [reservedSpot, setReservedSpot] = useState(surveyResponse.reserved_spot ?? false);
    const [originalReservedAmount, setOriginalInvestmentAmount] = useState(surveyResponse.reserved_amount ?? Math.ceil(surveyResponse.investment_amount_high / 1000) * 1000);
    const [reservedAmount, setReservedAmount] = useState(surveyResponse.reserved_amount ?? Math.ceil(surveyResponse.investment_amount_high / 1000) * 1000);
    const [formOpen, setFormOpen] = useState(false);

    const handleInterestChange = async () => {
        setReservedSpot(!reservedSpot);
    }

    const handleAmountChange = async (event: any) => {
        setReservedAmount(event.target.value);
    }

    const handleAmountSubmit = async () => {
        const { data, error } = await updateSurveyReponse(surveyResponse.public_id, { reserved_amount: reservedAmount, reserved_spot: reservedSpot });

        if (error) {
            console.error(error);
        } else {
            setOriginalInvestmentAmount(reservedAmount);
            //setDisplaySpvDetails(reservedSpot);
            setFormOpen(false);

            trackSurveyResponseEvent(
                surveyResponse,
                "Lifecycle - Revenue - Reserved",
                {
                    amount: reservedAmount,
                    survey_link_partner: surveyResponse.survey_link.partner_name,
                    project: surveyResponse.survey_link.project.public_title,
                    organization: surveyResponse.survey_link.project.organization.name,
                    survey_link_name: surveyResponse.survey_link.name,
                    survey_link_id: surveyResponse.survey_link.public_id,
                    survey_link: window.location.href
                }
            );
        }
    }

    const handleReserveClick = async () => {
        setFormOpen(true);
    }

    const handleSpvUrlClick = () => {
        trackSurveyResponseEvent(
            surveyResponse,
            "Lifecycle - Revenue - Clicked SPV",
            {
                survey_link_partner: surveyResponse.survey_link.partner_name,
                project: surveyResponse.survey_link.project.public_title,
                organization: surveyResponse.survey_link.project.organization.name,
                survey_link_name: surveyResponse.survey_link.name,
                survey_link_id: surveyResponse.survey_link.public_id,
                survey_link: window.location.href
            }
        );
        trackSurveyResponseEvent(surveyResponse, 'Clicked SPV link');
    }

    return (
        <>
            <div className="reservation-form">
                <h5>Your Reservation</h5>
                {!formOpen && !reservedSpot &&
                    <div className="display-box  flex-row">
                        <div>
                            <h5>Interested in participating?</h5>
                            <div>Reserve a spot to be notified when it's time to co-invest.</div>
                        </div>
                        <div className="launch-evaluation">
                            <div className="btn" onClick={handleReserveClick}>Reserve your spot</div>
                        </div>
                    </div>
                }
                {!formOpen && reservedSpot && (project.spv_stage === "approved" || project.spv_stage === "closed_canceled" || project.spv_platform_url === "") &&
                    <div className="display-box  flex-column dual-layer">
                        <div className="reservation-summary top-layer">
                            <div>
                                <h5>You reserved a spot to co-invest for ${reservedAmount.toLocaleString("en-US")}. (<span onClick={handleReserveClick} className="link bold">change</span>)</h5>
                                <div>We'll notify you when it's time to invest.</div>
                            </div>
                        </div>
                    </div>
                }
                {!formOpen && reservedSpot && project.spv_stage === "open" && project.spv_platform_url !== "" &&
                    <div className="display-box  flex-column">
                        {displaySpvDetails && surveyResponse.survey_link.project.spv_stage === "open" && project.spv_platform_url !== "" &&
                            <div className="flex-row bottom-layer">
                                <div>
                                    <h5>Complete your investment.</h5>
                                    <div>You reserved a spot to co-invest for ${reservedAmount.toLocaleString("en-US")}. (<span onClick={handleReserveClick} className="link bold">change</span>)</div>
                                </div>
                                <a className="btn btn-primary" href={project.spv_platform_url} target="_blank" rel="noreferrer" onClick={handleSpvUrlClick}>Co-Invest on {project.spv_platform_name}</a>
                            </div>
                        }
                    </div>
                }
                {formOpen &&
                    <div className="display-box  reservation-details">
                        <h5>Manage your reservation</h5>
                        <p>Let us know if you're interested and for how much. There's no commitment at this time.</p>
                        <div className="flex-row reservation-form">
                            <div className="reservation-control">
                                <FormLabel>Reserve a Spot</FormLabel>
                                <Switch checked={reservedSpot} onChange={handleInterestChange} />

                            </div>
                            <div className="reservation-control">
                                <FormLabel>Ideal Amount $ </FormLabel>
                                <input
                                    disabled={!reservedSpot}

                                    type="text"
                                    value={reservedAmount}
                                    onChange={handleAmountChange} />
                            </div>
                        </div>
                        <div className="reservation-form-submit">
                            <div className="btn btn-primary" onClick={handleAmountSubmit}>Confirm Reservation Details</div>
                        </div>
                    </div>
                }
            </div>

        </>
    )
}

const ReservationProgress = ({ surveyResponse }: any) => {
    const project = surveyResponse.survey_link.project;

    const currentTime = new Date();
    const currentDay = currentTime.setHours(0, 0, 0, 0);
    let daysToClose = project.funding_at ? Math.ceil(((new Date(project.funding_at)).getTime() - (new Date(currentDay)).getTime()) / (1000 * 60 * 60 * 24)) : -1;

    if (daysToClose < 0 && (project.spv_stage === "approved" || project.spv_stage === "open")) {
        daysToClose = 3;
    } else if (daysToClose < 0) {
        daysToClose = 0;
    }

    return (
        <>
            <h5>Investment Progress</h5>
            <div className="flex-row results-boxes reservation-stats">
                <div className="display-box white">
                    <p className="overview-results-value">{project.stats_angels} experts</p>
                    <p className="overview-results-title">Reserved Spots</p>
                </div>
                <div className="display-box white">
                    <p className="overview-results-value">${(Math.round(((project.stats_investment_amount_low + project.stats_investment_amount_high) / 2) / 1000) * 1000).toLocaleString("en-US")}</p>
                    <p className="overview-results-title">Soft Circled So Far</p>
                </div>
                <div className="display-box white">
                    <p className="overview-results-value">~{daysToClose} days</p>
                    <p className="overview-results-title">Until Funding Closes</p>
                </div>
            </div>
        </>
    )
}

export const EvaluationInvest = () => {
    const { surveyResponse, loading, investmentUnlocked } = useOutletContext<any>();
    const project = surveyResponse.survey_link.project;
    const { trackSurveyResponsePage } = useAnalytics();

    const [assetPagination, setAssetPagination] = useState({
        numPages: 1,
        currentPage: 1
    });

    const [displaySpvDetails, setDisplaySpvDetails] = useState(project.spv_stage === "open" || project.spv_stage === "approved");
    const [isOpen, setIsOpen] = useState(false);
    const [viewpointStats, setViewpointStats] = useState<any>(null);

    useEffect(() => {
        if (surveyResponse) {
            trackSurveyResponsePage(surveyResponse, 'expert flow - invest');
        }
    }, [surveyResponse]);

    //useEffect(() => {
    //    const loadViewpointStats = async () => {
    //        const { data, error } = await getViewpointStats(surveyResponse.public_id);
    //        if (data) {
    //            setViewpointStats(data.viewpointStats);
    //       }
    //    }
    //
    //     loadViewpointStats();
    //}, []);

    const handleNda = async () => {
        const { data, error } = await updateSurveyReponse(surveyResponse.public_id, { accepted_nda: true });

        if (error) {
            console.error(error);
        } else {
            window.location.reload();
        }
    }

    const handleAssetLoad = ({ numPages }: any) => {
        setAssetPagination({
            ...assetPagination,
            numPages: numPages,
            currentPage: 1
        });
    };

    const handleAssetChange = (event: any, offset: any) => {
        setAssetPagination({
            ...assetPagination,
            currentPage: assetPagination.currentPage + offset
        });
    };


    const handleLearnMore = () => {
        setIsOpen(true);
    }

    if (loading || !surveyResponse) return (<></>);



    return (
        <>
            <div className="flex-column left-column">
                <ContextMessage surveyResponse={surveyResponse} handleLearnMore={handleLearnMore} viewpointStats={viewpointStats} />
                {(project.spv_stage === "open" || project.spv_stage === "approved") && <ReservationDetails surveyResponse={surveyResponse} displaySpvDetails={displaySpvDetails} setDisplaySpvDetails={setDisplaySpvDetails} />}
                {(project.spv_stage === "open" || project.spv_stage === "approved") && <ReservationProgress surveyResponse={surveyResponse} />}
                {(project.spv_stage === "open" || project.spv_stage === "approved")  && <InvestmentMaterials surveyResponse={surveyResponse} displaySpvDetails={displaySpvDetails} setDisplaySpvDetails={setDisplaySpvDetails} />}
                <DealUpdates surveyResponse={surveyResponse} />
            </div>
            <div className="flex-column right-column">
                <EvaluationSummary surveyResponse={surveyResponse} investmentUnlocked={investmentUnlocked} />
                {(project.spv_stage === "open" || project.spv_stage === "approved") && <InvestmentActivity surveyResponse={surveyResponse} />}
            </div>
            <SpvFaq surveyResponse={surveyResponse} isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}