import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import NotFound from "../../../components/NotFound";
import useGetProjectData from "../../../hooks/useGetProjectData";
import useGetUserData from "../../../hooks/useGetUserData";
import Intercom from '@intercom/messenger-js-sdk';
import { useEffect } from "react";
import { DealSidebar } from "../components/DealSidebar";
import { useDealContext } from "../context";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../modules/ClientProvider";

import { DealCollaborationModal } from "../components/DealCollaborationModal";

export const Deal = () => {
    const { projectTag } = useParams();

    const { dealContext, setDealContext } = useDealContext();

    const [fetched, user] = useGetUserData();
    const location = useLocation();
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();

    const redirectGuest = async () => {
        const guestClient = ClientProvider.provideGuestClient();
        const { data, error } = await guestClient.login(user.emailAddress, user.name, user.company?.name, dealContext?.project?.project_share_links[0].share_url);
        if (data) {
            localStorage.setItem("jwtToken", data.token);
            navigate(`/guest/${dealContext?.project?.project_share_links[0].share_url}`);
        }
    }

    useEffect(() => {
        if (fetched && user && process.env.REACT_APP_INTERCOM_APP_ID) {
            Intercom({
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                user_id: user.uuid,
                email: user.emailAddress,
                name: user.name,
                created_at: user.createdAt
            });
        } else {
            console.warn('Intercom is not configured');
        }
    }, [fetched, user]);

    useEffect(() => {

        const fetchDealData = async () => {
            const accessToken = await getAccessTokenSilently();
            if (projectTag) {
                const { data, error } = await ClientProvider.provideProjectClient().getProjectData(projectTag, accessToken);
                if (data) {
                    data.project.result_json = data.project.result_json ? JSON.parse(data.project.result_json) : {};
                    data.project.revision_result_json = data.project.revision_result_json ? JSON.parse(data.project.revision_result_json) : {};
                    setDealContext({
                        project: data.project,
                        organization: data.project.organization,
                        role: data.role,
                        fetched: true
                    });
                } else {
                    navigate('/');

                }
            }
        }

        setDealContext({
            project: null,
            organization: null,
            role: null,
            fetched: false
        });
        fetchDealData();

        return () => {
            setDealContext({
                project: null,
                organization: null,
                role: null,
                fetched: false
            });
        }
    }, []);

    useEffect(() => {
        if (dealContext.project?.tag === projectTag && dealContext.project?.status === "draft" && !/\/setup$/.test(location?.pathname)) {
            navigate("setup");
        }
    }, [location.pathname, dealContext.project?.status]);

    if (!dealContext.fetched || !fetched) return <Loading />;

    if (dealContext.fetched && !dealContext.project) return <NotFound />;

    if (!user) return <NotFound />;

    const viewerRole = dealContext.role;

    if (dealContext.role === "guest") {
        redirectGuest();
        return <></>;
    }


    return (
        <>
            <aside className="page-sidebar">
                <DealSidebar project={dealContext.project} role={dealContext.role} />
            </aside>
            <main className="page-main deal-page sidebar">
                <Outlet context={{ organization: dealContext.project?.organization, project: dealContext.project, viewerRole: viewerRole, user: user }} />
            </main>
            <DealCollaborationModal project={dealContext.project} />
        </>
    );
};

