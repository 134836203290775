import { Box, Checkbox, FormLabel, Input, Modal, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { DealExecutive } from "../../../../features/deal/components/DealExecutive";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { DealProfileImage } from "../../../../features/deal/components/DealProfileImage";
import useGetRespondentHtml from "../../../../hooks/useGetRespondentHtml";
import { AdminViewpoint } from "./AdminViewpoint";


const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: "1rem",
    padding: "0"
};

const filterLabelStyle = {
    border: "1px solid #c9c9c9",
    borderRadius: "5px",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: "5px",
    fontSize: "0.9rem",
    "&:hover": {
        cursor: "pointer"
    }
};

const filterLabelCheckedStyle = {
    border: "1px solid #1A5DBD",
    backgroundColor: "#F0F6FF",
    borderRadius: "5px",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: "5px",
    fontSize: "0.9rem",
    color: "#000000",
    "&:hover": {
        cursor: "pointer"
    }
};

const bubbleBlackStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
    backgroundColor: "#ffffff",
    color: "#000000",
    borderRadius: "20px",
    height: "35px",
    width: "35px",
    border: "1px solid #c9c9c9",
    cursor: "pointer"
};

const displayTypeStyle = {
    fontSize: "1.5rem",
    color: "#c9c9c9",
    paddingBottom: "7px",
    "&:hover": {
        color: "#000000",
        cursor: "pointer"
    }
};

const displayTypeActiveStyle = {
    fontSize: "1.5rem",
    color: "#000000",
    paddingBottom: "7px",
    "&:hover": {
        color: "#000000",
        cursor: "pointer"
    }
};

const ViewpointHeading = styled("h5")(({ theme }) => ({
    fontSize: "1.5rem",
    margin: "1rem 0 0",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    "& span": {
        fontSize: "1rem",
        marginLeft: "1rem",
        color: "#000000",
        backgroundColor: "#c9c9c9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.5rem",
        width: "35px",
        height: "35px",
        borderRadius: "50%"
    }
}));

const ViewpointStaging = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    borderTop: "1px solid #000000",
    padding: "40px",
    marginBottom: "60px",
    marginTop: "20px",
    "& p": {
        padding: "30px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: "1px solid #c9c9c9"
    }
}));

type TViewpointsState = {
    viewpoints: any[];
    filteredViewpoints: any[];
};

const formatOptions = [
    {
        value: "has_video", label: "", icon: "film" as FontAwesomeIconProps["icon"]
    },
    {
        value: "has_audio", label: "", icon: "volume-high" as FontAwesomeIconProps["icon"]
    },
    {
        value: "has_text", label: "", icon: "keyboard" as FontAwesomeIconProps["icon"]
    }
]


const viewpointsReducer = (state: TViewpointsState, action: any) => {
    switch (action.type) {
        case 'FILTER':
            const { searchText, filterCriteria, sortCriteria } = action.payload;
            const filteredViewpoints = state.viewpoints.filter((viewpoint: any) => {
                const matchesSearchText = viewpoint.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                const matchesFormat = filterCriteria.format.length === 0 || filterCriteria.format.some((format: string) => {
                    if (format === "has_text") {
                        return !(viewpoint.has_video || viewpoint.has_audio);
                    } else if (format === "has_audio") {
                        return viewpoint.has_audio && !viewpoint.has_video;
                    } else {
                        return viewpoint[format]
                    }
                });
                return matchesSearchText && matchesFormat;
            })

            return {
                ...state,
                filteredViewpoints: filteredViewpoints.sort((a: any, b: any) => {
                    let aVal;
                    let bVal;

                    switch (sortCriteria.field) {

                        case 'name':
                            aVal = !a[sortCriteria.field] || a[sortCriteria.field] === "" ? 'zzzzzzz' : a[sortCriteria.field].toLowerCase();
                            bVal = !b[sortCriteria.field] || b[sortCriteria.field] === "" ? 'zzzzzzz' : b[sortCriteria.field].toLowerCase();
                            break;
                        case 'company':
                            aVal = !a[sortCriteria.field] || /^[^a-z]/i.test(a[sortCriteria.field]) ? 'zzzzzzz' : a[sortCriteria.field].toLowerCase();
                            bVal = !b[sortCriteria.field] || /^[^a-z]/i.test(b[sortCriteria.field]) ? 'zzzzzzz' : b[sortCriteria.field].toLowerCase();
                            break;
                        case 'format':
                            aVal = a.has_video ? 1 : a.has_audio ? 2 : 3;
                            bVal = b.has_video ? 1 : b.has_audio ? 2 : 3;
                            break;
                        case 'is_published':
                            aVal = a.is_published ? 0 : 1;
                            bVal = b.is_published ? 0 : 1;
                            break;
                        case 'hidden_from_guest':
                            aVal = a.hidden_from_guest ? 0 : 1;
                            bVal = b.hidden_from_guest ? 0 : 1;
                            break;
                        case 'included_in_analysis':
                            aVal = a.included_in_analysis ? 0 : 1;
                            bVal = b.included_in_analysis ? 0 : 1;
                            break;
                        default:
                            aVal = a[sortCriteria.field];
                            bVal = b[sortCriteria.field];
                    }

                    if (sortCriteria.direction === 'asc') {
                        return aVal > bVal ? 1 : -1;
                    } else {
                        return aVal < bVal ? 1 : -1;
                    }
                })
            };
            break;
        case 'UPDATE':
            const { viewpoints } = action.payload;
            return {
                ...state,
                viewpoints: viewpoints,
                filteredViewpoints: viewpoints
            };
            break;
        default:
            return state;
    }
}


export const AdminViewpointsList = ({ project, viewpoints, sectionName, reloadProjectData }: any) => {

    const [state, dispatch] = useReducer(viewpointsReducer, { viewpoints: viewpoints, filteredViewpoints: viewpoints });
    const [filterCriteria, setFilterCriteria] = useState<{ interest: any[], demand: any[], format: any[] }>({
        interest: [],
        demand: [], // Initialize 'demand' as an empty array
        format: [] // Initialize 'format' as an empty array
    });
    const [sortCriteria, setSortCriteria] = useState<{ field: string, direction: string }>({ field: 'alchemer_id', direction: 'asc' });
    const [searchText, setSearchText] = useState('');

    const handleFilter = (event: any) => {
        setSearchText(event.target.value);
    }


    const handleCriteriaChange = (event: any) => {
        const { name, value, checked } = event.target;

        switch (name) {
            case 'format':
                if (checked) {
                    setFilterCriteria(prev => ({ ...prev, format: [...prev.format, value] }));
                } else {
                    setFilterCriteria(prev => ({ ...prev, format: prev.format.filter((i: string) => i !== value) }));
                }
                return;
            default:
                setFilterCriteria(prev => ({ ...prev, [name]: checked }));
        }
    }

    const handleSortChange = (event: any) => {
        const { name, value } = event.target;
        setSortCriteria({ field: name, direction: value });
    }

    useEffect(() => {
        dispatch({
            type: 'FILTER',
            payload: {
                searchText: searchText,
                filterCriteria,
                sortCriteria
            }
        });
    }, [filterCriteria, sortCriteria, searchText, viewpoints]);

    useEffect(() => {
        dispatch({
            type: 'UPDATE',
            payload: {
                viewpoints
            }
        });
    }, [viewpoints]);

    return (

        <Box sx={{ marginBottom: "60px" }}>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                alignItems: "end",
                borderBottom: "1px solid #c9c9c9",
                paddingBottom: "10px",
            }}>
                <Box>
                    <Box sx={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                        <ViewpointHeading>{sectionName} <span>{state.filteredViewpoints.length}</span></ViewpointHeading>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "end",
                    gap: "10px",
                    fontSize: "1.5rem"
                }}>
                    <fieldset>
                        <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>SEARCH</Typography>
                        <Input placeholder="Search" onChange={handleFilter} />
                    </fieldset>
                    {/* Multiselect for 'format' */}
                    <fieldset>
                        <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>FORMAT</Typography>
                        {formatOptions.map((option) => (
                            <FormLabel key={option.value} sx={filterCriteria.format.includes(option.value) ? filterLabelCheckedStyle : filterLabelStyle}>
                                <Checkbox
                                    name="format"
                                    value={option.value}
                                    onChange={handleCriteriaChange}
                                    checked={filterCriteria.format.includes(option.value)}
                                    sx={{ display: "none" }}
                                /> <FontAwesomeIcon icon={option.icon} />
                            </FormLabel>
                        ))}
                    </fieldset>
                </Box>
            </Box>

            {state.filteredViewpoints.length === 0 ?
                <ViewpointStaging><p>No Viewpoints Found</p></ViewpointStaging>
                :
                <Table>
                    <TableHead>
                        <TableRow key="viewpoint-header">
                            <TableCell sx={{ fontWeight: 700, cursor: "pointer" }} onClick={() => handleSortChange({ target: { name: 'format', value: sortCriteria.field === "format" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}><FontAwesomeIcon icon="film" /></TableCell>
                            <TableCell sx={{ fontWeight: 700, cursor: "pointer" }} onClick={() => handleSortChange({ target: { name: 'alchemer_id', value: sortCriteria.field === "alchemer_id" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Alc. ID</TableCell>
                            <TableCell sx={{ fontWeight: 700, cursor: "pointer" }} onClick={() => handleSortChange({ target: { name: 'name', value: sortCriteria.field === "name" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Executive</TableCell>
                            <TableCell sx={{ fontWeight: 700, cursor: "pointer" }} onClick={() => handleSortChange({ target: { name: 'company', value: sortCriteria.field === "company" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Company</TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: "0.5rem"
                                }}>
                                    <Box onClick={() => handleSortChange({ target: { name: 'is_published', value: sortCriteria.field === "is_published" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>
                                        Visible to Company
                                    </Box>
                                    <Box onClick={() => handleSortChange({ target: { name: 'hidden_from_guest', value: sortCriteria.field === "hidden_from_guest" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>
                                        Visible to Guest
                                    </Box>
                                    <Box onClick={() => handleSortChange({ target: { name: 'included_in_analysis', value: sortCriteria.field === "included_in_analysis" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>
                                        Included in Analysis
                                    </Box>
                                </Box>
                            </TableCell>
                            { sectionName === "Review Queue" && <TableCell sx={{ fontWeight: 700 }}>Reject</TableCell> }
                            { sectionName === "Review Queue" && <TableCell sx={{ fontWeight: 700 }}>Approve</TableCell> }
                            { sectionName !== "Review Queue" && <TableCell sx={{ fontWeight: 700 }}>Highlight</TableCell> }
                            <TableCell sx={{ fontWeight: 700 }}>Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.filteredViewpoints?.map((viewpoint: any) => (
                            <AdminViewpoint project={project} viewpoint={viewpoint} reloadProjectData={reloadProjectData} />
                        ))}
                    </TableBody>
                </Table>
            }
        </Box>
    )


}