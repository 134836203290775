import { styled, Table, TableRow, TableCell, TableHead, TableBody, Typography, Select, MenuItem, Button, Box } from "@mui/material";
import { useGetProjectMembers } from "../../hooks/useGetProjectMembers";
import { useState } from "react";
import { UserProfileImage } from "../../../users";
import { useAuth0 } from "@auth0/auth0-react";
import { removeProjectMember, updateProjectMember } from "../../services/gutcheck-admin-api.service";
import { InviteMember } from "./InviteMember";
import { useGetProjectInvitations } from "../../hooks/usetProjectInvitations";
import { AddProjectMember } from "./AddProjectMember";
import { GenerateSetupLink } from "./GenerateSetupLink";

const MembersList = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    gap: '5px',
});

const roles = [
    { value: "admin", label: "Admin" },
    { value: "contributor", label: "Contributor" },
    { value: "guest", label: "Guest" },
];



const ProjectMember = ({ project, member, reloadProjectMembers }: any) => {

    const { getAccessTokenSilently } = useAuth0();

    const [memberForm, setMemberForm] = useState({
        role: member.role,
        editMode: false,
    });

    const [editMode, setEditMode] = useState(false);

    const handleChange = (e: any) => {
        setMemberForm({
            ...memberForm,
            role: e.target.value,
        });
    }

    const handleCancel = () => {
        setMemberForm({
            ...memberForm,
            role: member.role,
            editMode: false,
        });
    }

    const handleRemove = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await removeProjectMember(project.id, member.user.id, accessToken);

        if (data) {
            setMemberForm({
                ...memberForm,
                editMode: false,
            });
            reloadProjectMembers();
        }
    }

    const handleSubmit = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateProjectMember(project.id, member.user.id, memberForm.role, accessToken);

        if (data) {
            setMemberForm({
                ...memberForm,
                editMode: false,
            });
            reloadProjectMembers();
        }
    }

    return (
        <TableRow key={member.id}>
            <TableCell sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <UserProfileImage user={member.user} />
                <Typography variant="body1" sx={{ marginLeft: "10px" }}>
                    {member.user?.name} <br />
                    {member.user?.email_address}
                </Typography>
            </TableCell>
            <TableCell>
                <Select
                    value={memberForm.role}
                    onChange={(e) => handleChange(e)}
                    size="small"
                    disabled={!memberForm.editMode}
                >
                    {roles.map((role) => (
                        <MenuItem key={role.value} value={role.value} selected={role.value === memberForm.role}>{role.label}</MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell>
                {memberForm.editMode && <button onClick={handleSubmit}>Save</button>}
                {memberForm.editMode && <Button size="small" onClick={handleCancel}>Cancel</Button>}
                {memberForm.editMode && <Button size="small" sx={{ color: "red" }} className="remove-member" onClick={handleRemove}>Remove</Button>}
                {!memberForm.editMode && <button onClick={() => setMemberForm({ ...memberForm, editMode: true })}>Edit</button>}
            </TableCell>
        </TableRow>
    );
}


export const ProjectMembers = ({ project, user }: any) => {

    const projectMembers = useGetProjectMembers(project.id ?? "");
    const projectInvitations = useGetProjectInvitations(project.id ?? "");
    const [ openAddMember, setOpenAddMember ] = useState(false);

    const [editMember, setEditMember] = useState({
        open: false,
        member: null,
    });
    const [openInviteMember, setOpenInviteMember] = useState(false);
    const [openSetupLink, setOpenSetupLink] = useState(false);

    if (!projectMembers.fetched || !projectInvitations.fetched) return null;

    const handleEdit = (member: any) => {
        setEditMember({
            open: true,
            member: member,
        });
    }

    return (
        <Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h5>Members</h5>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "20px"}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setOpenSetupLink(true)}
                >Generate Setup Link</Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setOpenAddMember(true)}
                >Add Existing User</Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setOpenInviteMember(true)}
                >Invite New User</Button>
                </Box>
            </Box>
            <MembersList>

                <AddProjectMember project={project} reloadProjectMembers={projectMembers.reloadProjectMembers} open={openAddMember} setOpen={setOpenAddMember}/>
                <InviteMember project={project} reloadProjectMembers={projectInvitations.reloadProjectInvitations} open={openInviteMember} setOpen={setOpenInviteMember} />
                <GenerateSetupLink project={project} open={openSetupLink} setOpen={setOpenSetupLink} />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: "40%" }}>Member</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell sx={{ width: "40%" }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectMembers.members.map((member: any) => (
                            <ProjectMember project={project} member={member} reloadProjectMembers={projectMembers.reloadProjectMembers} key={member.id} />
                        ))}
                        {projectInvitations.invitations.filter((invitation: any) => !invitation.is_redeemed)?.map((invitation: any) => (
                            <TableRow key={invitation.id}>
                                <TableCell>
                                    <Typography>{invitation.invitee_name}</Typography>
                                    <Typography>{invitation.invitee_email}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{invitation.invitee_role}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>Invited</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </MembersList>
        </Box>
    );
}