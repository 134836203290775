import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';

type TGuestAuthContext = {
    token: string|null;
    setToken: React.Dispatch<React.SetStateAction<string|null>>;
    loading: boolean;
}

export const GuestAuthContext = createContext<TGuestAuthContext>({ token: null, setToken: () => {}, loading: true });

export const useGuestAuthContext = () => {
    const context = useContext(GuestAuthContext);
    if (!context) {
        throw new Error('useGuestAuthContext must be used within a GuestAuthProvider');
    }
    return context;
};

export const GuestAuthProvider = ({ children }: { children: ReactNode }) => {
    const [ token, setToken ] = useState<string|null>(null);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        const jwtToken = localStorage.getItem('jwtToken');
        setToken(jwtToken);
        setLoading(false);
    }, []);

    return (
        <GuestAuthContext.Provider value={{ token, setToken, loading }}>
            {children}
        </GuestAuthContext.Provider>
    );
}