import { useNavigate, useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { useEffect, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import { DealSetupInvite } from "../components/setup/DealSetupInvite";
import { DealSetupICP } from "../components/setup/DealSetupICP";
import { DealSetupAssets } from "../components/setup/DealSetupAssets";
import { DealSetupQuestions } from "../components/setup/DealSetupQuestions";
import ClientProvider from "../../../modules/ClientProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useDealContext } from "../context";
import { useUserContext } from "../../users";
import useAnalytics from "../../../hooks/useAnalytics";

const SetupSection = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "2rem",
    marginBottom: "1rem",
    position: "relative",
    "&.inactive": {
        "& h5": {
            cursor: "default",
            color: "#c9c9c9",
            "& span": {
                backgroundColor: "#c9c9c9",
                color: "#ffffff"
            }
        }
    },
}));


const SectionControl = styled(Box)(({ theme }) => ({
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1rem",
    "& .formfields": {
        backgroundColor: "#f8f9fa",
        boxShadow: "0 0 5px #c9c9c9",
        borderRadius: "10px",
        width: "100%",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "1rem",
        "& h5": {
            fontSize: "1.2rem",
            fontWeight: 600,
            margin: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "1rem",
            cursor: "pointer",
            "& span": {
                fontSize: "1rem",
                color: "#ffffff",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem",
                width: "35px",
                height: "35px",
                borderRadius: "50%"
            }
        },
    },
    "& .submit": {
        backgroundColor: "transparent",
        padding: "0",
        boxShadow: "none",
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        alignItems: "center",
        "& button": {
            width: "200px",
            marginLeft: "20px"
        }
    }
}));

const SectionTips = styled(Box)(({ theme }) => ({
    width: "30%",
    fontSize: "0.9rem",
}));

const SubmitSection = styled(Box)(({ theme }) => ({
    display: "flex",
    flextDirection: "row",
    justifyContent: "flex-end",
    width: "70%",
    "& button": {
        width: "200px",
    }
}));

export const DealSetup = () => {
    const { project, organization, viewerRole, user } = useOutletContext<{ project: any, organization: any, viewerRole: any, user: any }>();

    const { userContext, setUserContext } = useUserContext();
    const { dealContext, setDealContext, loadDealContext } = useDealContext();
    const [starting, setStarting] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>("");
    const { trackPage } = useAnalytics();


    const [form, setForm] = useState<any>({});

    const projectClient = ClientProvider.provideProjectClient();
    const navigate = useNavigate();

    const { getAccessTokenSilently } = useAuth0();
    const [sectionStates, setSectionStates] = useState<{ [key: string]: string; }>({
        invite: "open",
        icp: "open",
        assets: "closed",
        questions: "closed"
    });

    const [activeSection, setActiveSection] = useState<string>("invite");

    useEffect(() => {
        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - setup`, { organization: organization.tag, project: project.tag, role: userContext.projectMemberships.role });
        }
    }, [userContext]);

    const handleSetupSectionHover = (section: string) => {
        setActiveSection(sectionStates[section] === "open" ? section : "");
    }

    const handleToggleSection = (section: string) => {
        if (sectionStates[section] === "inactive") return;
        setActiveSection(sectionStates[section] === "open" ? "" : section);
        setSectionStates({
            ...sectionStates,
            [section]: sectionStates[section] === "closed" ? "open" : "closed"
        });
    }

    useEffect(() => {
        if (starting && project.status === "evaluation") {
            setStarting(false);
            navigate(`/o/${dealContext.project.organization.tag}/d/${dealContext.project.tag}`);
        }
    }, [starting, dealContext.project.status]);

    const handleStartDeal = async () => {
        setStarting(true);
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.start(project.id, accessToken);
        //loadDealContext(project.tag);
        if (data && data.project.status === "evaluation") {
            const projectResponse = await ClientProvider.provideProjectClient().getProjectData(project.tag, accessToken);
            if (projectResponse.data) {
                setDealContext({
                    ...dealContext,
                    project: projectResponse.data.project,
                    fetched: true
                });
            }
        } else if (error) {
            setSubmitError(error.message);
            setStarting(false);
        }
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: "100%",
            padding: "1rem",
            "& h5": {
                cursor: "pointer",
            }
        }}>
            <SetupSection className={sectionStates.invite}>
                <Box sx={{ width: "70%", paddingRight: "20px" }}>
                    {project.status === "draft" ?
                        <DealTitle
                            project={project}
                            section="Welcome!"
                            showShareButton={false}
                            subheading={<Typography>Collecting viewpoints and generating opportunities is easy. Just describe the product, the buyer, and the key questions you have. We’ll reach out to these buyers, collect their viewpoints, and present the insights and opportunities on this collaborative page.<br /><br />If there’s someone else that’s better to describe the product and buyer, invite them to the team and we’ll ask them to complete.</Typography>} />
                        :
                        <DealTitle project={project} showShareButton={false} section="Deal Setup" subheading="This information is used to find target buyers to provide viewpoints and generate opportunites." />
                    }
                </Box>
                <SectionTips className="display-box white" sx={{ position: "absolute", right: 0, zIndex: 1 }}>
                    {project.status === "draft" ? <h6>Need help completing this?</h6> : <h6>Invite Collaborators</h6>}
                    <p>Invite a {project.name} team member or {project.organization.name} team member to fill this out.</p>
                    <DealSetupInvite project={project} user={user} />
                </SectionTips>
            </SetupSection>

            {project.status === "draft" && <h6>To get started, tell us about {project.name}</h6>}

            {false &&
                <SetupSection className={sectionStates.invite}>
                    <SectionControl onMouseEnter={() => { handleSetupSectionHover("invite") }}>
                        <h5 onClick={() => handleToggleSection("invite")}><span>1</span> Invite the key people from {project.name} and {project.organization.name}</h5>
                        {sectionStates.invite === "open" && <DealSetupInvite project={project} user={user} />}
                    </SectionControl>
                    <SectionTips>
                    </SectionTips>
                </SetupSection>
            }

            <SetupSection className={sectionStates.icp}>
                <SectionControl onMouseEnter={() => { handleSetupSectionHover("icp") }}>
                    <Box className="formfields">
                        <h5 onClick={() => handleToggleSection("icp")}><span>1</span> Describe the ICP or target audience</h5>
                        {sectionStates.icp == "open" && <DealSetupICP project={project} user={user} />}
                    </Box>
                </SectionControl>
                <SectionTips sx={{ justifyContent: "flex-end", alignItems: "flex-end", display: "flex" }}>
                    {activeSection === "icp" &&
                        <Box>
                            <p><span className="bold">B2B Example</span><br />
                                VP Sales and VP Marketing at midsize companies (500 - 2500 employees) in the United States in Advertising, Media and Telecom verticals
                            </p>
                            <p><span className="bold">B2C Example</span><br />
                                U.S. women age 30-49 in households earning over $100,000 per year
                            </p>
                        </Box>
                    }
                </SectionTips>
            </SetupSection>

            <SetupSection className={sectionStates.assets}>
                <SectionControl onMouseEnter={() => { handleSetupSectionHover("assets") }}>
                    <Box className="formfields">

                        <h5 onClick={() => handleToggleSection("assets")}><span>2</span> Provide assets to share with executives</h5>
                        {sectionStates.assets === "open" && <DealSetupAssets project={project} user={user} />}
                    </Box>
                </SectionControl>
                <SectionTips>
                </SectionTips>
            </SetupSection>

            <SetupSection className={sectionStates.questions}>
                <SectionControl onMouseEnter={() => { handleSetupSectionHover("questions") }}>
                    <Box className="formfields">

                        <h5 onClick={() => handleToggleSection("questions")}><span>3</span> Tell us what you would like to learn</h5>
                        {sectionStates.questions === "open" && <DealSetupQuestions project={project} user={user} />}
                    </Box>
                    <Box className="submit">
                        {submitError !== "" && <Typography color="error">{submitError}</Typography>}
                        {project.status === "draft" && !starting &&
                            <button type="button" className="btn btn-primary" onClick={handleStartDeal}>Get Started</button>
                        }
                        {project.status === "draft" && starting &&
                            <button type="button" className="btn btn-primary disabled">Starting...</button>
                        }

                    </Box>

                </SectionControl>
                <SectionTips sx={{ overflowY: "visible" }}>
                    {activeSection === "questions" &&
                        <>
                            <p><span className="bold">Key Questions</span><br />
                                Many key questions are covered by default, so no need to repeat them. The default interview will ask about and measure:</p>
                            <ul>
                                <li>Overall demand and what drives demand</li>
                                <li>Key features and value propositions</li>
                                <li>Perceived value, price points and pricing models</li>
                                <li>Competitive products and competitive advantages</li>
                            </ul>
                            <p>If you have hypotheses about different business or individual characteristics that might result in higher demand, it would be helpful to list them. In B2B or B2C settings, you might want to learn:  </p>
                            <p><span className="bold">B2B Examples</span></p>
                            <ul>
                                <li>Are buyers that have outsourced their sales development function more likely to purchase?</li>
                                <li>Are buyers that use a SaaS based CRM product more likely to purchase?</li>
                            </ul>
                            <p><span className="bold">B2C Example</span></p>
                            <ul>
                                <li>Does frequent usage of social media increase demand for the product?</li>
                                <li>Are iPhone users more likely to subscribe than Android users?</li>
                            </ul>
                        </>
                    }
                </SectionTips>
            </SetupSection>


        </Box>
    );
}