import { callExternalApi } from "../../services/external-api.service";

class GuestHttpClient {

    _apiServerUrl;

    constructor(apiServerUrl = process.env.REACT_APP_API_ORIGIN) {
        this._apiServerUrl = apiServerUrl;
    }

    async getShareLinkData(shareLinkId: string) {
        const config = {
            url: `${this._apiServerUrl}/guest/${shareLinkId}`,
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async login(emailAddress: string, name: string, company: string, shareLinkId: string) {
        const config = {
            url: `${this._apiServerUrl}/guest/${shareLinkId}/login`,
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            data: {
                emailAddress,
                name,
                company
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async verifyToken(token: string, shareLinkId: string) {
        const config = {
            url: `${this._apiServerUrl}/guest/${shareLinkId}/verify`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async getDealData(shareLinkId: string, token: string) {
        const config = {
            url: `${this._apiServerUrl}/guest/deal/${shareLinkId}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getUserData(token: string) {
        const config = {
            url: `${this._apiServerUrl}/guest/self`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async getLeadsData(shareLinkId: string, token: string) {
        const config = {
            url: `${this._apiServerUrl}/guest/${shareLinkId}/leads`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getRespondentData(shareLinkId: string, respondentId: string, project: any, token: string) {
        const config = {
            url: `${this._apiServerUrl}/guest/${shareLinkId}/viewpoint/${respondentId}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getSandboxInvitation(token: string) {
        const config = {
            url: `${this._apiServerUrl}/guest/sandboxInvitation`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }
}

export default GuestHttpClient;