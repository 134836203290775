import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import useGetUserData from "../hooks/useGetUserData";
import { deleteSelf, getAllDataForSelf, verifySelf } from "../core/services/gutcheck-api.service";
import { useUserContext } from "../features/users";
import useAnalytics from "../hooks/useAnalytics";


export const SilentLogin = ({auth0User, isLoading}: any) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently }  = useAuth0();

  const { loginWithRedirect, logout } = useAuth0();
  const [ fetched, user ] = useGetUserData();
  const { userContext, setUserContext } = useUserContext();
  const { trackUser } = useAnalytics();


  useEffect(() => {
    const fetchUserData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAllDataForSelf(accessToken);

      if(data) {
        setUserContext({
          ...userContext,
          dbUser: data.user,
          projectMemberships: data.user?.memberships?.filter((membership: any) => { return membership.project }) || [],
          organizationMemberships: data.user?.memberships?.filter((membership: any) => { return membership.organization }) || [],
          fetched: true
        });
        trackUser(data.user);
      }
    }

    if (!isLoading) fetchUserData();

  }, []);

  useEffect(() => {
    const revertSignUp = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await deleteSelf(accessToken);
      logout({ logoutParams: { returnTo: `${process.env.REACT_APP_AUTH0_CALLBACK_URL}/unrecognized` }});

    }

    const completeSignUp = async () => {
      loginWithRedirect({
        appState: {
          returnTo: "/dashboard",
        },
        authorizationParams: {
          prompt: "none",
        },
      });
    }

    // User exists and is verified
    if (!isLoading && fetched && user && auth0User?.email_verified) {
      return navigate("/");  
    }

    // Users exists and is not verified
    if (!isLoading && fetched && user) {
      completeSignUp();
    };

    // User signed up but was rejected
    if (!isLoading && fetched && auth0User && !user) {
      //revertSignUp();
    }

    // User not authenticated
    if (!isLoading && fetched && !auth0User) {
      completeSignUp();
    }

    // return navigate("/", { state: { triedSilentLogin: true }});

  }, [ fetched, user ]);

  return (
      <Loading />
  );
};
