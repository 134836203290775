import { useGetProjectMembers } from "../../../../hooks/useGetProjectMembers";
import { useGetProjectInvitations } from "../../../../hooks/useGetProjectInvitations";
import { Box, styled, TableContainer, Typography, Table, TableHead, TableRow, TableBody, TableCell, FormControl, FormControlLabel, Switch, TextField, Stack, Menu, Select, MenuItem, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { DealMembersTable } from "./DealMembersTable";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../../modules/ClientProvider";
import { useDealContext } from "../../../deal";

export const SharingControls = ({ project, viewerRole }: any) => {

    const { getAccessTokenSilently } = useAuth0();
    const projectClient = ClientProvider.provideProjectClient();
    const { dealContext } = useDealContext();
    const [showControls, setShowControls] = useState(false);
    const [projectShareLink, setProjectShareLink] = useState(null);

    const shareLink = project.project_share_links[0];
    const [shareLinkForm, setShareLinkForm] = useState({
        is_active: shareLink.is_active,
        requires_approval: shareLink.requires_approval,
        display_executives: shareLink.display_executives,
        display_viewpoints: shareLink.display_viewpoints,
        display_analysis: shareLink.display_analysis,
        display_investors: shareLink.display_investors,
        display_prospects: shareLink.display_prospects,
        filter_demand_high: shareLink.filter_demand_high,
        filter_demand_medium: shareLink.filter_demand_medium,
        filter_demand_low: shareLink.filter_demand_low,
        filter_interest_demo: shareLink.filter_interest_demo,
        filter_interest_invest: shareLink.filter_interest_invest,
        filter_interest_advise: shareLink.filter_interest_advise,
        filter_icp: shareLink.filter_icp,
        filter: shareLink.filter_interest_demo || shareLink.filter_interest_invest || shareLink.filter_interest_advise
    });

    useEffect(() => {

        const fetchShareLink = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await projectClient.getProjectShareLink(project.id, shareLink.id, accessToken);

            if (data) {
                setShareLinkForm({
                    ...shareLinkForm,
                    ...data.projectShareLink,
                    filter: data.projectShareLink.filter_interest_demo || data.projectShareLink.filter_interest_invest || data.projectShareLink.filter_interest_advise
                })
            } else {
                console.error(error);
            }
        }

        fetchShareLink();
    }, []);

    const handleChange = async (event: any) => {

        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value === "true" ? true : event.target.value === "false" ? false : event.target.value;

        const shareLinkUpdate = {
            [event.target.name]: value
        };

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.updateProjectShareLink(project.id, shareLink.id, shareLinkUpdate, accessToken);

        if (data) {
            setShareLinkForm({
                ...shareLinkForm,
                ...data.projectShareLink
            })
        } else {
            console.error(error);
        }
    };

    const handleFilterToggle = async () => {
        const value = !shareLinkForm.filter;
        const shareLinkUpdate = {
            filter_interest_demo: value,
            filter_interest_invest: value,
            filter_interest_advise: value
        };

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.updateProjectShareLink(project.id, shareLink.id, shareLinkUpdate, accessToken);

        if (data) {
            setShareLinkForm({
                ...shareLinkForm,
                ...data.projectShareLink,
                filter: value
            })
        } else {
            console.error(error);
        }
    }

    if (viewerRole !== "admin") {
        return <Box></Box>
    }

    return (
        <Box>
            <h5>Guest Sharing Settings</h5>
            <Stack spacing={4}>
                <Box>
                    <p><strong>Who approves Guests before they gain access?</strong></p>
                    <Select
                        value={shareLinkForm.requires_approval}
                        onChange={handleChange}
                        name="requires_approval"
                        autoWidth={false}
                        sx={{ backgroundColor: "#ffffff", width: "280px" }}
                    >
                        <MenuItem value="true">Deal Admins approve Guests</MenuItem>
                        <MenuItem value="false">No one - the link is open</MenuItem>
                    </Select>
                </Box>

                <Box>
                    <p><strong>Which sections can Guests access?</strong></p>
                    <FormControlLabel name="display_viewpoints" control={<Switch size="small" checked={shareLinkForm.display_viewpoints} onChange={handleChange} />} label="Viewpoints" />
                    <FormControlLabel name="display_analysis" control={<Switch size="small" checked={shareLinkForm.display_analysis} onChange={handleChange} />} label="Analysis" />
                    <FormControlLabel name="display_prospects" control={<Switch size="small" checked={shareLinkForm.display_prospects} onChange={handleChange} />} label="Prospects" />
                    <FormControlLabel name="display_investors" control={<Switch size="small" checked={shareLinkForm.display_investors} onChange={handleChange} />} label="Angels" />
                </Box>

                <Box>
                    <p><strong>Which Executives viewpoints can Guests see?</strong></p>
                    <Box className="" sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%", alignItems: "flex-start", gap: "10px" }}>

                        <ToggleButtonGroup color="primary">
                            <ToggleButton value={!shareLinkForm.filter} selected={!shareLinkForm.filter} onClick={handleFilterToggle}>All Executives</ToggleButton>
                            <ToggleButton value={shareLinkForm.filter} selected={shareLinkForm.filter} onClick={handleFilterToggle}>Engaged Executives</ToggleButton>
                        </ToggleButtonGroup>
                        { !shareLinkForm.filter && <p>All Executives that provided a viewpoint, regardless of their interest.</p> }
                        { shareLinkForm.filter && <p>Executives interested in demo, trial, investing, or advising.</p> }

                    </Box>
                </Box>
            </Stack>
        </Box>
    );
}