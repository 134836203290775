import NavBar from "./NavBar";
import Footer from "./Footer";
import { ThemeProvider } from "@mui/material";
import { theme } from "../theme/theme";
import { useEffect } from "react";
import { getAllDataForSelf } from "../core/services/gutcheck-api.service";
import { useUserContext } from "../features/users";
import { useAuth0 } from "@auth0/auth0-react";
import useAnalytics from "../hooks/useAnalytics";

type PageProps = {
  children: any
}


export const BasicLayout = ({ children }: PageProps) => {

  const { userContext, setUserContext } = useUserContext();
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  const { trackUser } = useAnalytics();

  useEffect(() => {

    const fetchUserData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAllDataForSelf(accessToken);
      if (data) {
        setUserContext({
          ...userContext,
          dbUser: data.user,
          projectMemberships: data.user?.memberships?.filter((membership: any) => { return membership.project }) || [],
          organizationMemberships: data.user?.memberships?.filter((membership: any) => { return membership.organization }) || [],
          fetched: true
        });
        trackUser(data.user);
      }
    }

    if (user && !isLoading) fetchUserData();

    if (!user && !isLoading) {
      setUserContext({
        dbUser: null,
        projectMemberships: [],
        organizationMemberships: [],
        fetched: true
      })
    }

  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar hideUser={true} />
        <div className="page-wrapper">
          {children}
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};
