import { Route, Routes } from "react-router-dom"
import { DealOverview } from "../../deal/routes/DealOverview"
import { DealViewpoints } from "../../deal/routes/DealViewpoints"
import { DealAnalysis } from "../../deal/routes/DealAnalysis"
import { DealProspects } from "../../deal/routes/DealProspects"
import { DealIndustryAngels } from "../../deal/routes/DealIndustryAngels"
import { GuestAuthenticationGuard } from "../components/GuestAuthenticationGuard"
import { GuestDeal } from "./GuestDeal"
import { AppGuestLayout } from "../components/AppGuestLayout"
import { GuestAuthProvider } from "../components/GuestContext"

export const GuestRoutes = () => {
    return (
        <GuestAuthProvider>
            <AppGuestLayout>
                <Routes>
                    <Route path=":shareLinkId" element={<GuestAuthenticationGuard Component={GuestDeal} />}>
                        <Route index element={<DealOverview />} />
                        <Route path="viewpoints" element={<DealViewpoints />} />
                        <Route path="analysis" element={<DealAnalysis />} />
                        <Route path="prospects" element={<DealProspects />} />
                        <Route path="industry-angels" element={<DealIndustryAngels />} />
                    </Route>
                </Routes>
            </AppGuestLayout>
        </GuestAuthProvider>
    )
}