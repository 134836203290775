import { getOrganizationActivity } from "../../../core/services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { List, ListItem } from "@mui/material";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

export const WorkspaceActivity = ({ organization, projects }: any) => {

    const [activities, setActivities] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    const navigate = useNavigate();

    const defaultIconSvg = '<svg width="187" height="187" viewBox="0 0 187 187" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_847_348" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="187" height="187"><circle cx="93.5" cy="93.5" r="93.5" fill="#F8F9FA"/></mask><g mask="url(#mask0_847_348)"><circle cx="93" cy="94" r="93" fill="#F8F9FA"/><circle cx="93.5" cy="191.5" r="85.5" fill="#667788"/><circle cx="94" cy="80" r="41" fill="#C9C9C9"/></g></svg>';


    useEffect(() => {

        let isMounted = true;
        const fetchActivityData = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getOrganizationActivity(organization.id, accessToken);
            if (isMounted) {
                setActivities(data.activity);
            }
        }

        if (organization.id !== undefined) {
            fetchActivityData();
        }

        return () => {
            isMounted = false;
        };
    }, [organization]);

    const handleClick = (projectTag: string, projectExperience: string, uuid: string) => {
        const url = projectExperience === "winning" ? `/o/${organization.tag}/d/${projectTag}/viewpoints#${uuid}` : `/o/${organization.tag}/project/${projectTag}?tab=viewpoints&pid=${uuid}`;
        return navigate(url);
    }

    const handleImageError = (e: any) => {
        const srcString = `data:image/svg+xml,${encodeURIComponent(defaultIconSvg)}`;
        e.target.src = srcString 
    }


    if (activities?.length === 0) {
        return (
            <></>
        )
    }

    return (
        <div className="display-box">
            <h5>Recent Activity</h5>
            <List className="recent-activity-list">
                {activities?.map((activity: any, index: number) => {
                    const image = !activity.image || /placeholder\.png/.test(activity.image) ? defaultIconSvg : activity.image;
                    return (
                        <ListItem className="recent-activity-item" id={activity.uuid} key={index} onClick={() => handleClick(activity.project.tag, activity.project.experience, activity.uuid)}>
                            <div className="activity-photo-wrapper">
                                {image !== "" ? <img onError={handleImageError} className="activity-photo" src={image} alt="user" height={30} /> : <></>}
                            </div>
                            <p>{activity.hasVideo ? "Video viewpoint" : activity.hasAudio ? "Audio viewpoint" : "Viewpoint"} on <strong>{activity.project.name}</strong> {activity.companyName !== "" && <>from <strong>{activity.companyName}</strong></>}</p>
                        </ListItem>
                    )
                })}
            </List>
        </div>
    )
}