import { useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { Box } from "@mui/material";
import { useEffect } from "react";
import useAnalytics from "../../../hooks/useAnalytics";
import { useUserContext } from "../../users";

export const DealAnalysis = () => {
    const { project, organization, viewerRole } = useOutletContext<{ project: any, organization: any, viewerRole: any }>();
    const { userContext } = useUserContext();
    const { trackPage } = useAnalytics();

    useEffect(() => {
        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - analysis`, { organization: organization.tag, project: project.tag, role: userContext.projectMemberships.role });
        }
    }, [userContext]);

    return (
        <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
            <DealTitle viewerRole={viewerRole} project={project} section="Analysis" />
            <Box
            component={"iframe"}
                src={project.iterations?.at(-1)?.analysis_url}
                style={{
                    display: "flex",
                    flex: 1,
                    minWidth: "100%",
                }}
                frameBorder="0"
            >
            </Box>
        </Box>
    );
}   