
import { TProject } from "../types/types";
import { Component, useState } from "react";
import TInvitation from "../../invitations/types/Invitation";
import { approveInvitation, rejectInvitation, removeMemberFromProject } from "../../../core/services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import useAnalytics from "../../../hooks/useAnalytics";

export const ProjectGuestViewers = ({ project }: TProject) => {

    const { getAccessTokenSilently } = useAuth0();
    const { trackEvent } = useAnalytics();

    const [activeGuests, setActiveGuests] = useState(project.memberships?.map((membership: any) => membership.user));
    const [approvedGuests, setApprovedGuests] = useState(project.invitations?.filter((invitation: TInvitation) =>
        !invitation.is_redeemed && invitation.approval_status === "sent" && !activeGuests.map((user: any) => user?.email_address)?.includes(invitation.invitee_email) && invitation.invitee_role === "guest"
    ));
    const [pendingGuests, setPendingGuests] = useState(project.invitations?.filter((invitation: TInvitation) =>
        !invitation.is_redeemed && invitation.approval_status === "pending" && !activeGuests.map((user: any) => user?.email_address)?.includes(invitation.invitee_email) && invitation.invitee_role === "guest"
    ));


    const handleApproveGuest = async (event: any, invitationCode: string) => {

        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        const { data, error } = await approveInvitation(invitationCode, accessToken);

        if (error) throw error;

        if (data.invitation) {
            const newPendingGuests = pendingGuests.filter((invitation: TInvitation) => invitation.invitation_code !== data.invitation.invitation_code);
            setPendingGuests(newPendingGuests);

            const newApprovedGuests = [...approvedGuests, data.invitation];
            setApprovedGuests(newApprovedGuests);

            trackEvent("Approved guest invitation", { organization: project.organization.tag, project: project.tag, inviteeEmail: data.invitation.invitee_email });
        }
    }

    const handleRejectGuest = async (event: any, invitationCode: string) => {

        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        const { data, error } = await rejectInvitation(invitationCode, accessToken);

        if (error) throw error;

        if (data.invitation) {
            const newPendingGuests = pendingGuests.filter((invitation: TInvitation) => invitation.invitation_code !== data.invitation.invitation_code);
            setPendingGuests(newPendingGuests);
            const newApprovedGuests = approvedGuests.filter((invitation: TInvitation) => invitation.invitation_code !== invitationCode);
            setApprovedGuests(newApprovedGuests);

            trackEvent("Rejected guest invitation", { organization: project.organization.tag, project: project.tag, inviteeEmail: data.invitation.invitee_email });
        }
    }

    const handleRemoveGuest = async (event: any, userId: any) => {

        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        const { data, error } = await removeMemberFromProject(project.id, userId, accessToken);

        if (error) throw error;

        if (data.project) {
            const newActiveGuests = data.project.memberships?.map((membership: any) => membership.user);
            setActiveGuests(newActiveGuests);
        }
    }


    return (
        <div className="display-box overview-activity">
            <h4>External Guests</h4>
            <p>The following people have guest access to see the Viewpoints.</p>

            {pendingGuests.length > 0 &&
                <div className="guest-list pending">
                    <div className="guest-list-section-header">
                        <strong>Awaiting Approval</strong>
                    </div>
                    {pendingGuests?.map((invitation: TInvitation) => {
                        return (
                            <div key={invitation.invitation_code} className="guest-list-guest" id={invitation.invitation_code}>
                                <Tooltip key={invitation.invitation_code} title={`Invited by: ${invitation.source_user?.email_address} `} placement="top-start" arrow={true}>
                                    <div className="guest-list-guest-email" key={invitation.invitation_code}>{invitation.invitee_email}</div>
                                </Tooltip>

                                <div className="guest-list-guest-controls">
                                    <div className="guest-approve" onClick={(e) => handleApproveGuest(e, invitation.invitation_code)}><FontAwesomeIcon icon="check" /></div>
                                    <div className="guest-reject" onClick={(e) => handleRejectGuest(e, invitation.invitation_code)}><FontAwesomeIcon icon="x" /></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }

            {(activeGuests.length > 0 || approvedGuests.length > 0) &&
                <div className="guest-list active">
                    <div className="guest-list-section-header">
                        <strong>Guests</strong>
                    </div>
                    {activeGuests?.map((user: any) => {
                        return (
                            <div key={user?.email_address} className="guest-list-guest">
                                <div key={user?.email_address}>{user?.email_address}</div>
                                <div className="guest-reject" onClick={(e) => handleRemoveGuest(e, user.id)}><FontAwesomeIcon icon="x" /></div>
                            </div>
                        );
                    })}
                    {approvedGuests?.map((invitation: TInvitation) => {
                        return (
                            <div key={invitation.invitation_code} className="guest-list-guest">
                                <div key={invitation.invitation_code}>{invitation.invitee_email} &nbsp;&nbsp;
                                    <Tooltip title="Invitation Sent" placement="top" arrow={true}><FontAwesomeIcon icon="envelope" /></Tooltip></div>
                                <div className="guest-reject" onClick={(e) => handleRejectGuest(e, invitation.invitation_code)}><FontAwesomeIcon icon="x" /></div>
                            </div>
                        );
                    })}
                </div>
            }

        </div>

    );

}