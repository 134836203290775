import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { getInvitation } from "../../../core/services/gutcheck-api.service";
import type TInvitation from "../types/Invitation";
import { DocumentTitle } from "../../../components/DocumentTitle";
import Intercom from "@intercom/messenger-js-sdk";


export const Invitation = () => {

    const navigate = useNavigate();
    const { user, getAccessTokenSilently } = useAuth0();
    const { invitationCode } = useParams();
  
    const [invitation, setInvitation] = useState<TInvitation | null>(null);
    const [loading, setLoading] = useState(true);

    const {
        isLoading,
        isAuthenticated
    } = useAuth0();


    useEffect(() => {
        let isMounted = true;

        const fetchInvitationData = async () => {

            if (invitationCode) {
                const { data, error } = await getInvitation(invitationCode);
    
                if (!isMounted) return;
    
                if (data.invitation) {
                    setInvitation(data.invitation);
                    if (process.env.REACT_APP_INTERCOM_APP_ID) {
                        Intercom({
                            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                            email: data.invitation.invitee_email // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
                        });
                    } else {
                        console.warn('Intercom is not configured');
                    }
                }
                setLoading(false);
            }
        };

        if (invitationCode) {
            fetchInvitationData();
        }

        return () => { isMounted = false; };
    }, []);

    if (loading) return <></>;

    const expired = invitation?.expires_at ? new Date(invitation.expires_at) < new Date() : false;
    return (
        <main className="page-main">
            
            { invitation?.project ? <DocumentTitle pageTitle={`Invitation to ${invitation?.project?.name} Viewpoints`}/> : <DocumentTitle pageTitle={`Invitation to ${invitation?.organization?.name} Dealspace`}/> }
            <div className="gc_view">
                <Outlet context={{ invitation: invitation, loading: loading, expired: expired }}/>
            </div>
        </main>
    );


}
