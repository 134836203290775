import Loading from "../../../components/Loading";
import UserNotFound from "../../../components/UserNotFound";
import { Outlet, useNavigate, useParams, Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Intercom from '@intercom/messenger-js-sdk';
import { DocumentTitle } from "../../../components/DocumentTitle";
import { useUserContext } from "../../users";
import { useWorkspace } from "../hooks";
import { useAuth0 } from "@auth0/auth0-react";

export const Workspace = () => {
	const navigate = useNavigate();
	const { organizationTag } = useParams();
	//const [organization, setOrganization] = useState<any>(null);

	const { userContext, setUserContext } = useUserContext();
	const { organization, fetchOrganization, isLoading, error } = useWorkspace(organizationTag ?? "");

	const invitationCode = localStorage.getItem("invcode");

	if (invitationCode && invitationCode !== "") {
		//localStorage.removeItem("invcode");
		navigate(`/invitation/${invitationCode}/accept`);
		return;
	}

	useEffect(() => {

		if (userContext.fetched && userContext.dbUser && process.env.REACT_APP_INTERCOM_APP_ID) {
			Intercom({
				app_id: process.env.REACT_APP_INTERCOM_APP_ID,
				user_id: userContext.dbUser.uuid,
				email: userContext.dbUser.emailAddress,
				name: userContext.dbUser.name,
				created_at: userContext.dbUser.createdAt,
			});
		} else {
			console.warn('Intercom is not configured');
		}

	}, [userContext.fetched]);

	useEffect(() => {
		if (userContext.fetched && !isLoading && userContext.dbUser && !organization) {

			if (userContext.organizationMemberships.length === 0 && userContext.projectMemberships.length === 0) {
				navigate('/organization/new', { replace: true });
			} else if (userContext.organizationMemberships.length === 0) {
				const project = userContext.projectMemberships[0].project;
				const url = project.experience === "winning" ? `/o/${project.organization.tag}/d/${project.tag}` : `/o/${project.organization.tag}/project/${project.tag}`;
				navigate(url, { replace: true });
			} else if (!organizationTag) {
				navigate(`/o/${userContext.organizationMemberships[0].organization.tag}`, { replace: true });
			};
		} else if (userContext.fetched && !isLoading && organizationTag && organizationTag !== organization?.tag) {
			fetchOrganization(organizationTag);
		}

	}, [userContext.dbUser, organizationTag, isLoading]);

	if (!userContext.fetched) return <Loading />

	if (userContext.fetched && !userContext.dbUser) return <Loading />

	if (!organization) return <Loading />

	return organization ? (
		<>
			<DocumentTitle pageTitle={organization?.name} />
			<aside className="page-sidebar sidebar-light">
				<div className="sidebar-tab-container">
					<NavLink to={`/o/${organization.tag}`} end>
						<div className="sidebar-tab">
							<p><FontAwesomeIcon icon="list" /><span className="sidebar-label">&nbsp;&nbsp;Dashboard</span></p>
						</div>
					</NavLink>
					<NavLink to={`/o/${organization.tag}/settings`}>
						<div className="sidebar-tab">
							<p><FontAwesomeIcon icon="gear" /><span className="sidebar-label">&nbsp;&nbsp;Settings</span></p>
						</div>
					</NavLink>
				</div>
			</aside>
			<main className="page-main sidebar">
				<div className="gc_view">
					<Outlet context={{ organization: organization }} />
				</div>
			</main>
		</>
	) : '';
};


