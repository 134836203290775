import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "./components/Loading";
import EmailVerification from "./components/EmailVerification";
import { useAuth0 } from "@auth0/auth0-react";
import { SilentLogin } from "./components/SilentLogin";
import { SignUp } from "./components/SignUp";
import { CallbackPage } from "./views/pages/CallbackPage";
import { PageLayout } from "./components/PageLayout";
import "./App.css";
import initFontAwesome from "./utils/initFontAwesome";
import AppLayoutRoutes from "./routes/AppLayoutRoutes";
import BasicLayoutRoutes from "./routes/BasicLayoutRoutes";
import UnrecognizedUser from "./components/UncrecognizedUser";
import { AppLayout } from "./components/AppLayout";
import { DealProvider } from "./features/deal/context";
import { UserProvider } from "./features/users";
import { GuestRoutes } from "./features/guest/routes";


export const App = () => {
    initFontAwesome();

    const { user, isLoading, error, loginWithRedirect } = useAuth0();

    if (error) {
        if (error.message === 'Login required') {
            loginWithRedirect({
                appState: {
                    returnTo: "/dashboard",
                }
            });

        } else {
            return <div>Oops... {error.message}</div>;
        }
    };

    if (isLoading) {
        return (
            <Loading />
        );
    };

    if (user && !user.email_verified) {
        return (
            <UserProvider>
                <DealProvider>
                    <Routes>
                        <Route path="/login/silent" element={<SilentLogin auth0User={user} isLoading={isLoading} />} />
                    </Routes>
                    <AppLayout>
                        <Routes>
                            <Route path="/unrecognized" element={<UnrecognizedUser />} />
                            <Route path="*" element={<EmailVerification auth0User={user} isLoading={isLoading} />} />
                        </Routes>
                    </AppLayout>
                </DealProvider>
            </UserProvider>
        );
    };

    return (
        <UserProvider>
            <DealProvider>
                <Routes>
                    {/* Pages WITHOUT layout */}
                    <Route path="/login/silent" element={<SilentLogin />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/callback" element={<CallbackPage />} />
                    <Route path="/e/*" element={<BasicLayoutRoutes />} />
                    <Route path="/guest/*" element={<GuestRoutes />} />
                    {/* Pages WITH layout */}
                    <Route path="*" element={<AppLayoutRoutes />} />

                </Routes>
            </DealProvider>
        </UserProvider>
    );
};