import { useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { DealExecutivesList } from "../components/DealExecutivesList";
import { DealOverviewStats } from "../components/DealOverviewStats";
import { DealLeads } from "../components/DealLeads";
import { useEffect } from "react";
import useAnalytics from "../../../hooks/useAnalytics";
import { useUserContext } from "../../users";

export const DealOverview = () => {
    const { project, organization, viewerRole, user } = useOutletContext<{ project: any, organization: any, viewerRole: any, user: any }>();
    const { trackPage } = useAnalytics();
    const { userContext } = useUserContext();

    useEffect(() => {
        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - executives`, { organization: organization.tag, project: project.tag, role: userContext.projectMemberships.role });
        }
    }, [userContext]);

    return (
        <div>
            { project.viewpoints.length > 0 ?
                <DealTitle viewerRole={viewerRole} project={project} section="Meet Your Executives" subheading="So far, they've generated ..." />
                :
                <DealTitle viewerRole={viewerRole} project={project} section="We're Finding Executives" subheading="And will let you know when they start weighing in ..." />
            }
            { project?.viewpoints.length > 0 && <DealOverviewStats project={project} viewerRole={viewerRole} /> }
            <DealExecutivesList project={project} viewpoints={project?.viewpoints} user={user} />
            <DealLeads project={project} />
        </div>
    );
}   