import { Route, Routes } from "react-router-dom";
import { Evaluation } from "./Evaluation";
import { EvaluationExit } from "./EvaluationExit";
import { EvaluationEvaluate } from "./EvaluationEvaluate";
import { EvaluationInvest } from "./EvaluationInvest";
import { EvaluationInstructions } from "./EvaluationInstructions";
import { EvaluationPopout } from "./EvaluationPopout";

export const EvaluationsRoutes = () => {
    return (
        <Routes>
            <Route path="exit" element={<EvaluationExit />} />
            <Route path="popout" element={<EvaluationPopout />} />
            <Route path=":evaluationId" element={<Evaluation />} >
                <Route index element={<EvaluationEvaluate />} />
                <Route path="expert/:expertId" element={<EvaluationEvaluate />} />
                <Route path="expert/:expertId/invest" element={<EvaluationInvest />} />
                <Route path="expert/:expertId/invest/instructions" element={<EvaluationInstructions />} />
            </Route>
        </Routes>
    );
}