import { Box, Button, Typography } from "@mui/material"
import { useOutletContext } from "react-router-dom";
import useAnalytics from "../../../hooks/useAnalytics";
import { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import { styled } from "@mui/system";

const InstructionsSection = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    "& .instruction-section-header": {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        "& h6": {
            marginBottom: "0",
        },
        "& span": {
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#f5f5f5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
        }
    },
    "& .instruction-section-content": {
        backgroundColor: "#f5f5f5",
        padding: "20px",
        marginLeft: "50px",
        borderRadius: "5px",
    }

}));

export const EvaluationInstructions = () => {
    const { surveyResponse, loading, investmentUnlocked } = useOutletContext<any>();
    const project = surveyResponse.survey_link.project;
    const { trackSurveyResponsePage, trackSurveyResponseEvent } = useAnalytics();

    useEffect(() => {
        if (surveyResponse) {
            trackSurveyResponsePage(surveyResponse, 'expert flow - invest - instructions');
        }
    }, [surveyResponse]);

    if (loading) {
        return <Loading />
    }

    const handleInstructionsClick = () => {
        trackSurveyResponsePage(surveyResponse, 'expert flow - invest - instructions - start');
        // Open constrained popup for instructions
        window.open(`/e/popout?platform=${project.spv_platform_name}`, '_blank', 'width=800,height=600,scrollbars=yes,resizable=yes,toolbar=no,location=no');
    }

    const handleSpvUrlClick = () => {
        trackSurveyResponseEvent(
            surveyResponse,
            "Lifecycle - Revenue - Clicked SPV",
            {
                survey_link_partner: surveyResponse.survey_link.partner_name,
                project: surveyResponse.survey_link.project.public_title,
                organization: surveyResponse.survey_link.project.organization.name,
                survey_link_name: surveyResponse.survey_link.name,
                survey_link_id: surveyResponse.survey_link.public_id,
                survey_link: window.location.href
            }
        );
        trackSurveyResponseEvent(surveyResponse, 'Clicked SPV link');
        window.open(project.spv_platform_url, '_blank');
    }

    return (
        <Box>
            <h5>
                Instructions and Tips on investing through {project.spv_platform_name}
            </h5>

            <InstructionsSection>
                <Box className="instruction-section-header">
                    <span>1</span>
                    <h6>
                        Your relationshop with BrightLoop
                    </h6>
                </Box>
                <Box className="instruction-section-content">Details about your relationship with BrightLoop</Box>
            </InstructionsSection>
            <InstructionsSection>
                <Box className="instruction-section-header">
                    <span>2</span>
                    <h6>
                        Your relationshop with BrightLoop
                    </h6>
                </Box>
                <Box className="instruction-section-content">Details about your relationship with BrightLoop</Box>
            </InstructionsSection>
            <InstructionsSection>
                <Box className="instruction-section-header">
                    <span>3</span>
                    <h6>
                        Your relationshop with BrightLoop
                    </h6>
                </Box>
                <Box className="instruction-section-content">Details about your relationship with BrightLoop</Box>
            </InstructionsSection>
            <Button variant="contained" color="primary" onClick={handleSpvUrlClick}>Launch SPV</Button>
        </Box>
    )
}