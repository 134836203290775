import { postEvent, postPage, postSurveyResponseEvent, postSurveyResponsePage } from "../core/services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";

const useAnalytics = () => {

    const analytics = (window as any).analytics;
    const useClient = process.env.REACT_APP_ENABLE_SEGMENT === "true" && analytics.initialized;
    const trackingEnabled = process.env.REACT_APP_ENABLE_SEGMENT === "true";
    const { getAccessTokenSilently } = useAuth0();

    const trackPage = async (page: string, additionalData: any = {}) => {
        if (!trackingEnabled) {
            return;
        }
        if (useClient) {
            analytics.page(page, {
                ...additionalData
            });
        } else {
            // TODO: Handle guest users
            try {
                const accessToken = await getAccessTokenSilently();
                const newPage = await postPage(
                    page,
                    {
                        ...additionalData,
                        url: window.location.href,
                        path: window.location.pathname,
                        title: document.title,
                        referrer: document.referrer
                    },
                    accessToken
                );
            } catch (error) {
                console.error("Error tracking page", error);
            }
        }
    };

    const trackEvent = async (event: string, additionalData: any = {}, callback: any = null) => {
        if (!trackingEnabled) {
            if (callback) {
                callback();
            }
            return;
        } else {
            if (useClient) {
                analytics.track(event, {
                    ...additionalData
                }, callback);
            } else {
                // TODO: Handle guest users
                try {
                    const accessToken = await getAccessTokenSilently();
                    const newEvent = await postEvent(
                        event,
                        {
                            ...additionalData
                        },
                        accessToken
                    );

                    if (callback) {
                        callback();
                    }
                } catch (error) {
                    console.error("Error tracking event", error);
                }
            }
        }
    }

    const trackUser = (user: any, additionalData: any = {}) => {
        if (!trackingEnabled) {
            return;
        }
        if (useClient) {
            analytics.identify(user.uuid, {
                ...additionalData,
                externalId: user?.externalId,
                email: user?.emailAddress ?? "",
                is_expert: false,
                latest_user_type: user.memberships?.find((membership: any) => (membership.organization && membership.role === "contributor")) ? "firm" :
                    user.memberships?.find((membership: any) => (membership.project && membership.role === "contributor")) ? "company" :
                    user.memberships?.find((membership: any) => (membership.project && membership.role === "guest")) ? "guest" : null
            });
        }
    }

    const trackSurveyResponsePage = async (surveyResponse: any, page: string, additionalData: any = {}, callback: any = null) => {
        if (!trackingEnabled) {
            if (callback) {
                callback();
            }
            return;
        } else {
            if (useClient) {
                analytics.page(page, {
                    ...additionalData,
                    organization: surveyResponse?.survey_link?.project?.organization?.name ?? "",
                    project: surveyResponse?.survey_link?.project?.public_title ?? ""
                });
            } else {
                const newPage = await postSurveyResponsePage(surveyResponse.uuid, page, {
                    ...additionalData,
                    organization: surveyResponse?.survey_link?.project?.organization?.name ?? "",
                    project: surveyResponse?.survey_link?.project?.public_title ?? "",
                    url: window.location.href,
                    path: window.location.pathname,
                    title: document.title,
                    referrer: document.referrer
                });
                if (callback) {
                    callback();
                }
            }
        }

    }

    const trackSurveyResponseEvent = async (surveyResponse: any, event: string, additionalData: any = {}, callback: any = null) => {
        if (!trackingEnabled) {
            if (callback) {
                callback();
            }
            return;
        } else {
            if (useClient) {
                analytics.track(event, {
                    ...additionalData,
                    organization: surveyResponse?.organization?.name ?? "",
                    project: surveyResponse?.project?.public_title ?? ""
                }, callback);
            } else {
                const newEvent = await postSurveyResponseEvent(surveyResponse.uuid, event, {
                    ...additionalData,
                    organization: surveyResponse?.organization?.name ?? "",
                    project: surveyResponse?.project?.public_title ?? ""
                });

                if (callback) {
                    callback();
                }
            }
        }
    }

    const trackSurveyResponseUser = async (surveyResponse: any, additionalData: any = {}) => {
        if (!trackingEnabled) {
            return;
        }
        const emailAddress = surveyResponse.email_address && surveyResponse.email_address !== "" ? surveyResponse.email_address : surveyResponse.viewpoint?.email;
        if (useClient) {
            analytics.identify(surveyResponse.uuid, {
                ...additionalData,
                email: emailAddress,
                is_expert: true,
                latest_user_type: "expert"
            });
        }
    }

    return { trackPage, trackEvent, trackUser, trackSurveyResponsePage, trackSurveyResponseEvent, trackSurveyResponseUser };


};

export default useAnalytics;